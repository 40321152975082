import { Component, OnInit, Renderer2 } from "@angular/core";
import { BaseComponent } from "@app/base/base.component";
import { ProductPageRouteService } from "@app/services/product-page-route.service";
import { StyleService } from "@app/services/style/style.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent extends BaseComponent implements OnInit {
  public displayLoader: "none" | "block" = "block";
  constructor(
    public renderer: Renderer2,
    public styleService: StyleService
  ) {
    super(renderer);
    this.styleService.load('bootstrap', 'toastr').then(data => {
    }).catch(error => console.log(error));
  }

  ngOnInit(): void {
    this.hideLoader();
  }
}
