import { Component, OnInit, Input } from "@angular/core";
import { TitleModalConfig } from "@shared/title-modal-config";

@Component({
  selector: "app-response-title",
  templateUrl: "./response-title.component.html",
  styleUrls: ["./response-title.component.scss"],
})
export class ResponseTitleComponent implements OnInit {
  emphasisTitle: string;
  additionalTitle: string;
  icon: string;
  logo: boolean;

  constructor(titleConfig: TitleModalConfig) {
    this.emphasisTitle = titleConfig.emphasisTitle;
    this.additionalTitle = titleConfig.additionalTitle;
    this.icon = titleConfig.icon;
    this.logo = titleConfig.logo;
  }

  ngOnInit(): void {}
  ngOnChanges(t) {}
}
