import { Injectable } from '@angular/core';

export interface DYProductParameter {
  Partner: string;
  Market: string;
  Product: string;
  Vertical: string;
  Currency: string;
  Data: string;
}
export interface DYTravelVariables {
  destinationType: string; // Destination type of travel, it can be "Nacional" or "Internacional".
  travelType: string; // Types of travel, it can be "Ida y regreso" or "Sólo ida"...
  origin: string; // City of origin of trip
  destination: string; // City of destination of the trip
  destinationRegion: string; // Travel destination region (Local, América del sur, etc)​
  startDate: Date; // Policy start date
  endDate: Date; // Policy Expiration Date
  additionalTravellers: number; // Represents the number of additional passengers
  insuredDuration: number; // Represents the number of days that the insurance will last
  leadTime: number; // Represents the number of days in advance that the insurance was purchased
  paymentFrequency?: string; // Montly, Bimontly, Quarterly, Yearly...
  principalAge?: string; // Represents the age of the main user
  secondaryAge?: string; // Represents the ages of secondary users, separated by ","
  leadId?: string; // Lead id when the user fill the form with personal data. This variable is obtained when the user fills out the form with personal data and must be kept throughout the flow
}

export interface DYAutoVariables {
  autoBrand: string; // Brand of the vehicle
  autoModel: string; // Model of the vehicle
  autoVersion: string; // Version of the vehicle
  autoYear: number; // Year of the vehicle
  autoUse: string; // Use that the client gives to the vehicle (Personal / Work)
  autoType: string; // (New) type of vehicle insured (Auto / Pickup)
}

export interface DYHospiVariables {
  coverageType: string; // Selected Coverage Type: "Cualquier causa", "Accidente"...
  protectionType: string; // Selected Protection Type: "Hospi", "Total", "Hogar"... (This value is can be selected from a combo)
}

@Injectable({
  providedIn: 'root'
})
export class DynamicYieldSessionStorageService {
  constructor() { }

  getDYProductParameters(): DYProductParameter {
    const dyProductParameters = sessionStorage.getItem('DYProductParameters');
    if (dyProductParameters) {
      return JSON.parse(dyProductParameters);
    } else {
      return {} as DYProductParameter;
    }
  }

  setDYProductParameters(dyProductParameters: DYProductParameter) {
    sessionStorage.setItem('DYProductParameters', JSON.stringify(dyProductParameters))
  }

  getDYTravelVariables(): DYTravelVariables {
    const dyTravelVariables = sessionStorage.getItem('DYTravelVariables');
    if (dyTravelVariables) {
      return JSON.parse(dyTravelVariables);
    } else {
      return {} as DYTravelVariables;
    }
  }

  setDYTravelVariables(dYTravelVariables: DYTravelVariables) {
    sessionStorage.setItem('DYTravelVariables', JSON.stringify(dYTravelVariables))
  }

  updateDYTravelVariables(updatedDYTravelVariables: Partial<DYTravelVariables>) {
    const dyTravelVariables = this.getDYTravelVariables()
    const updatedValues = { ...dyTravelVariables, ...updatedDYTravelVariables };

    this.setDYTravelVariables(updatedValues)
  }

  // AUTO SESSION STORAGE
  getDYAutoVariables(): DYAutoVariables {
    return JSON.parse(sessionStorage.getItem('DYAutoVariables') || '')
  }

  setDYAutoVariables(dYAutoVariables: DYAutoVariables) {
    sessionStorage.setItem('DYAutoVariables', JSON.stringify(dYAutoVariables))
  }

  updateDYAutoVariables(updatedDYAutoVariables: Partial<DYAutoVariables>) {
    const dyAutoVariables = this.getDYAutoVariables()
    const updatedValues = { ...dyAutoVariables, ...updatedDYAutoVariables };

    this.setDYAutoVariables(updatedValues)
  }

  // HOSPI SESSION SOTRAGE
  getDYHospiVariables(): DYHospiVariables {
    return JSON.parse(sessionStorage.getItem('DYHospiVariables') || '')
  }

  setDYHospiVariables(dYHospiVariables: DYHospiVariables) {
    sessionStorage.setItem('DYHospiVariables', JSON.stringify(dYHospiVariables))
  }

  updateDYHospiVariables(updatedDYHospiVariables: Partial<DYHospiVariables>) {
    const dyHospiVariables = this.getDYHospiVariables()
    const updatedValues = { ...dyHospiVariables, ...updatedDYHospiVariables };

    this.setDYHospiVariables(updatedValues)
  }
}
