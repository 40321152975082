<div class="title-container" [ngClass]="{
  'success': data.configTitle?.bgclass === 'success',
  'fail': data.configTitle?.bgclass === 'fail',
  'bg-white': data.configTitle?.emphasisTitle === ''
  }">
  <button *ngIf="!data.noShowClose" data-cy="close-modal" mat-icon-button class="close-button"
    [mat-dialog-close]="false" [disableRipple]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>

  <h1 *ngIf="data.title" mat-dialog-title class="title">{{ data.title }}</h1>
  <ng-container *ngComponentOutlet="data.titleComponent;injector:createInjector(titleConfig)">
  </ng-container>

</div>

<div style="background-color: white;" class="content-container" [ngClass]="{
  'content-container__one-cta': confirmActions?.confirmLabel,
  'content-container__two-cta': confirmActions?.cancelLabel
}" (validateConfirmEvent)="validateConfirm($event)"
  [attr.data-cy]="data.dataCy"> <!--mat-dialog-content-->
  <ng-container #dialogContainer *ngComponentOutlet="data.component; injector: injector;">
  </ng-container>
  <div *ngIf="!otpService.isValidOTP" class="alert alert-danger mb-0 p-2 row provisional-alert" role="alert">
    <small>{{otpService.msgError}}</small>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert($event)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-form #formchild *ngIf="fields" [ngClass]="{'mt-4': otpService.isValidOTP, 'mt-2': !otpService.isValidOTP}"
    class="d-block form-modal" [description]="description" [formName]="formName" [fields]="fields" [isDialog]="true"
    [submitText]="submitText" (changesEvent)="changesForm($event)" (close)="closeDialog()"
    (parentEvent)="submitOnDialog($event)">
    <div *ngIf="bottomText" class="child-container" [innerHTML]="bottomText | safeHtml"></div>
  </app-form> <!--(submit)="submitOnDialog($event)-->
  <h3 *ngIf="confirmTitle" class="mt-5 text-center confirm-title">{{confirmTitle}}</h3>
  <p *ngIf="confirmText" [ngClass]="{'mt-5': !fields, 'confirm-text-w-form': fields }"
    class="text-center confirm-text text-prewrap">{{confirmText}}</p>
  <div *ngIf="confirmHTML" [innerHTML]="confirmRawHTML" class="mt-5 text-center confirm-text"></div>


</div>
<mat-dialog-actions style="background-color: white;" class="mt-auto options-confirm" *ngIf="confirmActions">
  <button data-cy="confirm-modal" *ngIf="confirmActions && confirmActions.confirmLabel"
    [disabled]="confirmActions.confirmDisabled" mat-flat-button color="accent" class="mb-2 mb-lg-3 text-white"
    [mat-dialog-close]="confirmData || true">
    {{confirmActions.confirmLabel}}
    <mat-icon *ngIf="confirmActions && confirmActions.confirmIcon">{{confirmActions.confirmIcon}}</mat-icon>
  </button>
  <button data-cy="cancel-modal" *ngIf="confirmActions && confirmActions.cancelLabel" mat-stroked-button color="primary"
    class="ml-0 mb-3" mat-dialog-close>{{confirmActions.cancelLabel}}</button>
</mat-dialog-actions>
