import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CampaignService, CustomerInfoService } from './services';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedirectVidaSaludGuard implements CanActivate {
  constructor(
    private campaignService: CampaignService, 
    private customerService: CustomerInfoService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const {prefixUrl, type} = this.campaignService.getSaleStatus();
    const resultUrl = this.urlFromSaleType(type, prefixUrl);
    const queryParams = this.campaignService.getParamsQueryString();
    this.router.navigateByUrl(`${resultUrl}${queryParams}`);
    return true;
  }

  urlFromSaleType(saleType: string, prefixUrl: string) {
    return  `/salud-vida-assist/oferta/${prefixUrl}/pagina-inicio`;
  }
}
