import { Renderer2 } from "@angular/core";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  AfterViewChecked,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { valueInListValidator } from "@app/shared/custom-validators";
import { isObject } from "@helpers/utils";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
@Component({
  selector: "app-field-choices",
  templateUrl: "./field-choices.component.html",
  styleUrls: ["./field-choices.component.scss"],
})
export class FieldChoicesComponent
  implements OnInit, OnChanges, AfterViewChecked
{
  @Input() formGroup: UntypedFormGroup;
  @Input() key: string;
  @Input() value: string;
  @Input() hasLogo: boolean;
  @Input() logoDefault: string;
  @Input() config: any;
  @Input() configAutocomplete: any = null;
  @Input() choices: string[] | { key: string; value: string }[];
  @Output() updateChoice = new EventEmitter();
  @Input() checkbox: boolean;
  controlAutocomplete = new UntypedFormControl("", [Validators.required]);
  optionsAutocomplete: string[];
  optionsAutocompleteLowerCase: string[];
  filteredOptions: Observable<string[]>;
  @ViewChild("autocompleteInput") acEl: ElementRef;
  constructor() {
    this.optionsAutocomplete = this.configAutocomplete?.dataList || [];
  }

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  ngAfterViewChecked(): void {}

  isObject = isObject;

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsAutocomplete.filter(
      (option) => option.toLowerCase().indexOf(filterValue) != -1 
    );
  }

  selectOption(opt: string, closed = true) {
    if (closed && this.configAutocomplete) {
      this.configAutocomplete = {
        ...this.configAutocomplete,
        closed: true,
      };
    }
    this.value = opt;
    this.updateChoice.emit({ [this.key]: opt });
  }

  selectCheck(opt) {
    let check = document.getElementsByTagName("input");

    for (let i = 0; i < check.length; i++) {
      if (check[i].type == "checkbox" && check[i].name != opt) {
        check[i].checked = opt.checked;
        (document.getElementById(check[i].id) as any).readOnly = false;
      } else if (check[i].type == "checkbox" && check[i].name == opt) {
        (document.getElementById(check[i].id) as any).readOnly = true;
      }
    }
    this.value = opt;
    this.updateChoice.emit({ [this.key]: opt });
  }

  onImgError($event, src) {
    $event.target.src = src;
  }

  toggleAutocomplete($event) {
    const inputEl = this.acEl.nativeElement;
    this.acEl.nativeElement?.focus();
    if (this.configAutocomplete?.closed) {
      this.value = null;
      this.updateChoice.emit({ [this.key]: null });
    }
    if (this.optionsAutocomplete.length == 0) {
      this.configAutocomplete?.remoteList.subscribe((data) => {
        this.optionsAutocomplete = data;
        this.controlAutocomplete.setValidators([
          Validators.required,
          valueInListValidator(data.map((el) => el.toLowerCase())),
        ]);
        this.filteredOptions = this.controlAutocomplete.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
        this.optionsAutocompleteLowerCase = data.map((i) => i.toLowerCase());
        this.acEl.nativeElement?.focus();
      });
    }
    this.configAutocomplete = {
      ...this.configAutocomplete,
      closed: false,
    };
  }

  checkTypeAutocomplete($event) {
    const textMatch = this.optionsAutocompleteLowerCase.findIndex((i) => {
      return i === $event.target.value.toLowerCase().trim();
    });
    if (textMatch != -1) {
      this.selectOption(
        this.optionsAutocomplete[textMatch],
        this.configAutocomplete?.closed
      );
    } else {
      this.value = null;
      this.updateChoice.emit({ [this.key]: null });
    }
  }
}
