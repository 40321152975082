import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-field-daterangepicker",
  templateUrl: "./field-daterangepicker.component.html",
  styleUrls: ["./field-daterangepicker.component.css"],
})
export class FieldDaterangepickerComponent implements OnInit {
  @Input() id;
  @Input() key;
  @Input() label;
  @Input() value;
  @Output() updateRangeEvent = new EventEmitter<any>();
  range = new UntypedFormGroup({
    start: new UntypedFormControl("", [Validators.required]),
    end: new UntypedFormControl("", [Validators.required]),
  });
  constructor() {}

  ngOnInit(): void {}

  updateDateRangeValue($event, key) {
    this.updateRangeEvent.emit({ ...$event, key });
  }
}
