import { Component } from "@angular/core";
import { ProductPageRouteService } from "@app/services/product-page-route.service";
@Component({
  selector: "app-public-layout",
  templateUrl: "./public-layout.component.html",
  styleUrls: ["./public-layout.component.scss"],
})
export class PublicLayoutComponent {
  public hideLayout: boolean = false;
  constructor(public productPageRouteService: ProductPageRouteService) {
    this.productPageRouteService.showOrHideLayout$.subscribe((msg) => {
      this.hideLayout = msg;
    });
  }
}
