import { lastValueFrom, map } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from "../../environments/environment";

export interface City {
  bgCityCode: string;
  bgCityName: string;
  bgProvinceCode: string;
  s6CityCode: string;
  s6CityName: string;
  s6ProvinceCode: string;
  shortName: string;
}

@Injectable({
  providedIn: "root",
})
export class CityService {
  data: any;
  constructor(
    private httpClient: HttpClient,
  ) { }

  getData() {
    return this.data;
  }

  getCityById(id: any) {
    return this.data.find(d => d.bgCityCode == id);
  }

  async getAllCities(): Promise<City[]> {
    return await lastValueFrom(this.httpClient.get<City[]>(`${env.baseUrl}city/allCities`).pipe(map((response: any) => {
      this.data = response.data.map((e:City) => { return {...e, eTag: ""} });
      return this.data;
    })));
  }
}
