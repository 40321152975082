import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-secure-menu',
  templateUrl: './secure-menu.component.html',
  styleUrls: ['./secure-menu.component.scss'],
})
export class SecureMenuComponent {
  constructor() {}
}