import {Injectable} from "@angular/core";
import { StyleStoreService } from "./style.store";

declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class StyleService {
  private stylesheets: any = {};

  constructor(public styleStore: StyleStoreService) {
    this.styleStore.StyleStore.forEach((stylesheet: any) => {
      this.stylesheets[stylesheet.name] = {
        loaded: this.styleStore.isLoadedStyle(stylesheet.name),
        href: stylesheet.href
      };
    });
  }

  load(...stylesheets: string[]) {
    var promises: any[] = [];
    stylesheets.forEach((stylesheet) => promises.push(this.loadStylesheet(stylesheet)));
    return Promise.all(promises);
  }

  loadStylesheet(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.stylesheets[name].loaded) {
        resolve({stylesheet: name, loaded: true, status: 'Already Loaded'});
      }
      else {
        //load stylesheet
        let stylesheet = document.createElement('link');
        stylesheet.rel = 'stylesheet';
        stylesheet.type = 'text/css';
        stylesheet.href = this.stylesheets[name].href;
        if (stylesheet.readyState) {  //IE
          stylesheet.onreadystatechange = () => {
            if (stylesheet.readyState === "loaded" || stylesheet.readyState === "complete") {
              stylesheet.onreadystatechange = null;
              this.stylesheets[name].loaded = true;
              this.styleStore.addLoadedStyle(name);
              resolve({stylesheet: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {  //Others
          stylesheet.onload = () => {
            this.stylesheets[name].loaded = true;
            this.styleStore.addLoadedStyle(name);
            resolve({stylesheet: name, loaded: true, status: 'Loaded'});
          };
        }
        stylesheet.onerror = (error: any) => resolve({stylesheet: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(stylesheet);
      }
    });
  }
}
