<p class="mt-5 text-center confirm-text text-prewrap">
  Lo sentimos mucho al momento no pudimos procesar tu pago. Para tu tranquilidad, no se ha realizado ningún cargo a tu cuenta / tarjeta.
</p>
<p class="text-center">
  Te recomendamos estas opciones:
</p>
<div class="alert alert-secondary" role="alert">
  <ul class="ordered-list">
    <li>
      <a class="link-item" (click)="closeModal($event)">Selecciona otro método de pago</a>
    </li>
    <li>
      <a class="link-item" (click)="closeModal($event)">Selecciona otro plan</a>
    </li>
    <li>
      <a class="link-item" (click)="closeModal($event)">Ver otros productos similares</a>
    </li>
  </ul>
</div>
