import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PortalService } from "@app/modules/home/services/portal.service";
import { environment as env } from "@environments/environment";
import { map } from "rxjs/operators";

interface amountBenLvl {
  productId?: string;
  campaignCode?: string;
  description?: string;
  benefitLevel: string | string[];
  prefixValue?: string;
  selectValue?: number | string;
  value: number | string | number[];
  suffixValue?: string;
}
interface Coverage {
  fromProduct: string[];
  idCov?: string;
  pic: string;
  selectIcon?: string;
  justInHome?: boolean;
  description: string;
  descriptionHTML?: string;
  additionalDesc?: string;
  noShowInTable?: boolean;
  descriptionCells?: any[];
  content: string;
  contentRangeAge?: any[];
  asteriskText?: string;
  mergeAmounts?: boolean;
  accordionTop?: boolean;
  amounts: amountBenLvl[];
  infoExtra?: boolean;
  descExtra?: string;
  noDeductible?: boolean;
  accordionTitleText?: boolean;
  noShowCoverage?: boolean;
  contentAssitance?: boolean;
  isAssistance?: boolean;
  isLegal?: boolean;
  oneCoverage?: boolean;
  sumCoverageNoChange?: boolean;
  positionAssistance?: number;
  isDeductibleExtra?: boolean;
  reasonsCheck?: boolean;
  titleAssistanceLanding?: string;
}
@Injectable({
  providedIn: "root",
})
export class ProductService {
  SALUDASSISTDEV = {
    year: "SALUD1",
    month: "SALUM1",
  };
  SALUDASSISTPROD = {
    year: "SALUDA",
    month: "SALUME",
  };
  SALUDASSIST = {
    year: env.saludassistyearName,
    month: env.saludassistmonthName,
  };
  constructor(
    private httpClient: HttpClient,
    public portalService: PortalService
  ) {}

  private assists = [];

  private exclusions = [];

  public testimonials = null;

  private coverages: Coverage[] = [];
  products = [];

  private relatedProducts;

  public setRelatedProducts(productsList) {
    this.relatedProducts = productsList;
  }
  public getRelatedProductsList() {
    return this.relatedProducts;
  }

  public getProductHasRangeAgePrices(input) {
    const source = this.getProductByName(input).benefitLevels[0].discountFee;
    return Array.isArray(source);
  }

  public getProductsByRelatedName(input, rangeAge = null, saleType = "") {
    if (rangeAge && this.getProductHasRangeAgePrices(input)) {
      return this.products
        .filter((prod) => prod.relatedProducts.includes(input))
        .map((prod) => {
          var prodRes = { ...prod };
          var benefitLevelsUpdated = [];
          prodRes.benefitLevels.forEach((el) => {
            benefitLevelsUpdated.push({
              ...el,
              discountFee: el.discountFee.find(
                (dsc) => dsc.rangeAge == rangeAge
              ).amount,
              discountDollars: el.discountDollars.find(
                (dsc) => dsc.rangeAge == rangeAge
              ).amount,
              discountCents: el.discountCents.find(
                (dsc) => dsc.rangeAge == rangeAge
              ).amount,
              marketFee: el.marketFee.find((dsc) => dsc.rangeAge == rangeAge)
                .amount,
              premiumFee: el.premiumFee.find((dsc) => dsc.rangeAge == rangeAge)
                .amount,
              sumAssured: el.sumAssured.find((dsc) => dsc.rangeAge == rangeAge)
                .amount,
            });
          });
          prodRes.benefitLevels = benefitLevelsUpdated;
          return prodRes;
        });
    }
    let prods = this.products.filter((prod) =>
      prod.relatedProducts.includes(input)
    );
    this.setRelatedProducts(prods[0].relatedProducts);
    if (prods[0]?.hasF2F && saleType == "F2F") {
      /*let prodsF2F = this.productsF2FOnly.filter((prod) =>
        prod.relatedProducts.includes(input)
      );
      return [...prods, ...prodsF2F];*/
    }
    return prods;
  }

  public getBenLvlsWRangeAge(input, rangeAge) {
    const prod = this.products.find((prod) => prod.name === input);

    var prodRes = { ...prod };
    var benefitLevelsUpdated = [];
    prodRes.benefitLevels.forEach((el) => {
      benefitLevelsUpdated.push({
        ...el,
        discountFee: el.discountFee.find((dsc) => dsc.rangeAge == rangeAge)
          .amount,
        discountDollars: el.discountDollars.find(
          (dsc) => dsc.rangeAge == rangeAge
        ).amount,
        discountCents: el.discountCents.find((dsc) => dsc.rangeAge == rangeAge)
          .amount,
        marketFee: el.marketFee.find((dsc) => dsc.rangeAge == rangeAge).amount,
        premiumFee: el.premiumFee.find((dsc) => dsc.rangeAge == rangeAge)
          .amount,
        sumAssured: el.sumAssured.find((dsc) => dsc.rangeAge == rangeAge)
          .amount,
      });
    });
    prodRes.benefitLevels = benefitLevelsUpdated;
    return prodRes;
  }

  public getProductByName(input, typeSaleOnly = "") {
    if (typeSaleOnly?.startsWith("F2F")) {
      /*return this.productsF2FOnly.find((prod) =>
        prod.relatedProducts.includes(input)
      );*/
    }
    const byProductId = this.products.find((prod) => {
      return prod.name === input;
    });
    if (!byProductId) {
      let product;
      product = this.products.find((prod) => {
        return prod.relatedProducts.includes(input);
      });
      if (!product) {
        product = this.products[0];
      }
      return product;
    }
    return byProductId;
  }

  public getProductInfoByCode(input, plan = null) {
    let product = this.products.find(
      (prod) => JSON.stringify(prod.code) == JSON.stringify(input)
    );
    /*if (plan?.saleTypeOnly) {
      if (plan.saleTypeOnly.startsWith("F2F")) {
        product = this.productsF2FOnly.find((prod) => prod.code == input);
      }
    } else {
      product = this.products.find((prod) => prod.code == input);
    }*/
    product = { ...product };
    delete product.benefitLevels;
    return product;
  }

  public getProductByURL(url) {
    return this.products.find((prod) => prod.url === url);
  }

  public getProductByURLStarts(url) {
    return this.products.find((prod) => url.startsWith(prod.url));
  }

  public hasProductPreviousSteps(input) {
    return !!this.getProductByName(input).prevSteps;
  }

  public getJustCoveragesByProduct(productName: string) {
    return this.coverages.filter((cov) =>
      cov.fromProduct.includes(productName)
    );
  }

  public getJustExclusionsByProduct(productName: string) {
    return this.exclusions.find((cov) => cov.fromProduct.includes(productName));
  }

  public getAssistsByProduct(productName: string, benefitLevel: string) {
    const hasAssists = this.assists.find((cov) =>
      cov.fromProduct.includes(productName)
    );
    return hasAssists?.asisstBenefits
      ? hasAssists.asisstBenefits.filter((el) => {
          return el.fromBenefitLevel.includes(benefitLevel);
        })
      : [];
  }

  public getCoveragesByProduct(productName: string, benefitLevel: string) {
    return this.coverages
      .filter((cov) => cov.fromProduct.includes(productName))
      .map((amt) => {
        let accordionData;
        if (amt.accordionTop) {
          accordionData = {
            accordionTop: amt.accordionTop,
            amounts: amt.amounts.filter((amt) =>
              Array.isArray(amt.benefitLevel)
                ? amt.benefitLevel.find((bf) => bf == benefitLevel)
                : amt.benefitLevel == benefitLevel
            ),
          };
        } else {
          accordionData = {
            amount: amt.amounts.find((val) => {
              return Array.isArray(val.benefitLevel)
                ? val.benefitLevel.find((bf) => bf == benefitLevel)
                : val.benefitLevel == benefitLevel;
            }).value,
          };
        }
        return {
          ...accordionData,
          idCov: amt.idCov,
          pic: amt.pic,
          selectIcon: amt.selectIcon,
          justInHome: amt.justInHome,
          description: amt.description,
          additionalDesc: amt.additionalDesc,
          noShowInTable: amt.noShowInTable,
          content: amt.content,
          mergeAmounts: amt.mergeAmounts,
          infoExtra: amt.infoExtra,
          descExtra: amt.descExtra,
          noDeductible: amt.noDeductible,
          accordionTitleText: amt.accordionTitleText,
          noShowCoverage: amt.noShowCoverage,
          isAssistance: amt.isAssistance,
          oneCoverage: amt.oneCoverage,
          sumCoverageNoChange: amt.sumCoverageNoChange,
          contentAssitance: amt.contentAssitance,
          positionAssistance: amt.positionAssistance,
          isDeductibleExtra: amt.isDeductibleExtra,
        };
      });
  }

  public getExclusionsByProduct(productName: string) {
    return this.exclusions.filter((cov) =>
      cov.fromProduct.includes(productName)
    );
  }

  public getDiscount(value: number, percentage = 20) {
    return value + (percentage / 100) * value;
  }

  public setProducts(data) {
    this.products = data.products;
    this.coverages = data.coverages;
    this.exclusions = data.exclusions;
    this.assists = data.assists;
    this.testimonials = data?.testimonials || null;
  }

  public resetProducts() {
    this.products = [];
    this.coverages = [];
    this.exclusions = [];
    this.assists = [];
    this.testimonials = null;
  }

  public getProducts() {
    return this.products;
  }

  public getProductUrlRemote(productId: string) {
    return this.httpClient
      .post(`${env.baseUrl}productcodenv/urlbyid`, {
        product_id: productId,
      })
      .pipe(map((data: any) => data.data));
  }

  public getProductIdRemote(url: string) {
    return this.httpClient
      .post(`${env.baseUrl}productcodenv/prodidbyurl`, {
        Url: url,
      })
      .pipe(map((data: any) => data.data));
  }

  public getProductsRemote(inputData) {
    const portalMode = this.portalService.portalMode;
    return this.httpClient
      .post(`${env.baseUrl}productcodenv/productdata`, inputData)
      .pipe(
        map((data: any) => {
          const prods = data.data.products.filter(
            (e) =>
              !e?.portalModeOnly ||
              (e.portalModeOnly && e.portalModeOnly == portalMode)
          );
          data.data.products = prods;
          this.setProducts(data.data);
          return data.data;
        })
      );
  }
}
