import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";

import { CampaignService } from "@app/services";
import { Injectable } from "@angular/core";
import { LoaderService } from "@services/loader.service";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  requestCount = 0;
  currentMessage: string;
  defaultMessage: string =
    "Por favor espera, estamos recibiendo tus datos para que los puedas confirmar.";
  callUsStatus: boolean = false;
  timeOut;
  constructor(
    public loaderService: LoaderService,
    private campaignService: CampaignService
  ) {}

  timeStart() {
    this.timeOut = setTimeout(() => {
      this.loaderService.showCallUs();
    }, 3000);
  }
  timeEnd() {
    clearTimeout(this.timeOut);
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.requestCount++;
    this.loaderService.show();
    this.timeStart();
    const httpRequest = request.clone({
      headers: new HttpHeaders({
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
      }),
    });
    return next.handle(httpRequest).pipe(
      finalize(() => {
        this.requestCount--;
        if (this.requestCount === 0) {
          this.loaderService.hide();
          this.timeEnd();
          this.campaignService.sendWaitingMessage();
        }
      })
    );
  }
}
