<footer class="text-center">
  <!--*ngIf="layout === 'legacy'"-->
  <div class="bg-blue-help">
    <section class="container section-need-help content">
      <h1 class="mb-4 title-need-help">¿Necesitas ayuda? ¡Contáctanos!</h1>
      <p class="mb-5 subtitle-need-help">
        Estamos listos para resolver tus dudas, podemos asistirte en tu proceso de compra si lo necesitas.
      </p>
      <button data-cy="footer-agendar2" class="btn-blue-help" mat-stroked-button (click)="openScheduleDialog()" [appGatrackButton]="sendLabel"
        data-cy="footer-ctc">{{sendLabel}}</button>
      <br>
      <a class="btn-blue-help whatsapp-button" mat-stroked-button
        href="https://api.whatsapp.com/send?phone=59323420092&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20los%20seguros%20que%20Chubb%20tiene%20para%20clientes%20Banco%20Guayaquil%20😊."
        target="_blanc">Chat Whatsapp</a>
      <!--button class="bt-style-bg border border-white">Chat WhatsApp</button-->
    </section>
  </div>
  <div class="bg-md-primary content text-center">
    <section class="container section-inside-footer">
      <ul class="contact-list list-unstyled">
        <li class="contact-item">
          <a class="contact-link" href="tel:1700-111-999">
            <mat-icon svgIcon="click_to_call-white"></mat-icon>
            <span class="contact-text">1700-111-999</span>
          </a>
        </li>
        <li class="contact-item">
          <a class="contact-link" href="tel:02-395-7731">
            <mat-icon svgIcon="click_to_call-white"></mat-icon>
            <span class="contact-text">02-395-7731</span>
          </a>
        </li>
        <li class="contact-item">
          <a class="contact-link" href="mailto:chubbecuador@chubb.com?subject=E-Commerce BG - Llamada agendada">
            <mat-icon svgIcon="envelope-white"></mat-icon>
            <span class="contact-text">chubbecuador@chubb.com</span>
          </a>
        </li>
        <li class="contact-item">
          <a class="contact-link"
            href="https://api.whatsapp.com/send?phone=59323420092&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20los%20seguros%20que%20Chubb%20tiene%20para%20clientes%20Banco%20Guayaquil%20😊."
            target="_blank">
            <mat-icon svgIcon="whatsapp-white"></mat-icon>
            <span class="contact-text">Chat Whatsapp</span>
          </a>
        </li>
      </ul>
      <hr class="divider" />
      <ul class="legal-list list-unstyled">
        <li>
          <a [routerLink]="['/terms-conditions']">Términos y Condiciones</a>
        </li>
        <li>
          <a [routerLink]="['/privacy-policies']">Políticas de Privacidad</a>
        </li>
      </ul>
      <div>
        <figure class="my-4 pt-4 d-flex justify-content-between container-logos">
          <img alt="Logo de Chubb Seguros" ngSrc="../assets/svg/logo-chubb-white.svg" width="123" height="12" />
          <img alt="Logo de Banco Guayaquil" class="bg" ngSrc="../assets/img/bg-white-logo.png" width="134" height="38" />
        </figure>

        <small class="copyright">
          © 2020 Chubb Group. Productos ofrecidos por una o más de las Compañías de Chubb Group para clientes Banco
          Guayaquil. Los productos ofrecidos no se encuentran disponibles en todas las jurisdicciones. Los derechos
          sobre la marca comercial "Chubb", su logotipo, y demás marcas relacionadas, son de propiedad de Chubb Limited.
        </small>
      </div>
    </section>
  </div>
</footer>
