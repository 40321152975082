import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SelectedDataService } from "./selected-data.service";
import { Subject } from "rxjs";
import { environment as env } from "@environments/environment";
import { getDataForContainerPage } from "@app/@shared/helpers/utils";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ProductPageRouteService {
  private data: any;
  private dataSelected: any;
  private _showOrHideLoaderSource = new Subject<boolean>();
  showOrHideLoader$ = this._showOrHideLoaderSource.asObservable();
  private _showOrHideLayoutSource = new Subject<boolean>();
  showOrHideLayout$ = this._showOrHideLayoutSource.asObservable();
  private _typeLayoutSource = new Subject<string>();
  typeLayout$ = this._typeLayoutSource.asObservable();
  private _dataSelectedSource = new Subject<any>();
  dataSelected$ = this._dataSelectedSource.asObservable();
  constructor(
    private httpClient: HttpClient,
    private selectedDataService: SelectedDataService
  ) {}

  public sendLoaderMessage(message: boolean = false) {
    this._showOrHideLoaderSource.next(message);
  }

  public sendLayoutMessage(message: boolean = false) {
    this._showOrHideLayoutSource.next(message);
    if (message === false) {
      this.setData(null);
    }
  }

  public sendLayoutTypeMessage(message: string = 'legacy') {
    this._typeLayoutSource.next(message);
    if (message === 'legacy') {
      this.setData(null);
    }
  }

  public sendDataSelectedMessage(message: any) {
    this._dataSelectedSource.next(message);
    this.setDataSelected(message);
  }

  public getData() {
    return this.data;
  }

  public setDataSelected(data: any) {
    this.data = {
      ...this.data,
      selectedData: {
        ...this.data.dataSelected,
        ...data,
      },
    };
    // this.selectedDataService.saveSelectedDataInLocalStorage(this.data.selectedData);
  }

  public setDataConfig(data: any) {
    this.data = {
      ...this.data,
      config: {
        ...this.data.config,
        ...data,
      },
    };
  }

  public getDataSelected() {
    return this.data?.selectedData;
  }

  public getDataConfig() {
    return this.data?.config;
  }

  public setData(data: any) {
    this.data = data;
  }

  public getPageData(inputData, saleStatus) {
    const dataModel = getDataForContainerPage(inputData, saleStatus);
    return this.httpClient.post(`${env.baseUrl}product-page`, dataModel).pipe(
      map((data: any) => {
        this.setData(data.data);
        return data.data;
      })
    );
  }

  /*public getBenefitLevelsBySumAssured(sumAssured: number) {
    return this.data.config.benefitLevels.filter(
      (el) => el.sumAssured === sumAssured
    );
  }

  public getBenefitLevelByCovRelSumAssured(
    covRel: string[],
    sumAssured: number
  ) {
    return this.data.config.benefitLevels.find(
      (el) =>
        JSON.stringify(covRel) === JSON.stringify(el.covRel) &&
        el.sumAssured === sumAssured
    );
  }*/

  /*public setBenefitLevelByCodeRelSumAssured(
    codeRel: string[],
    sumAssured: number
  ) {
    this.data.selectedData.benefitLevel = this.getBenefitLevelByCodeRelSumAssured(
      codeRel,
      sumAssured
    );
  }*/
}
