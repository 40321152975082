import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AuthorizationInfoComponent } from "../authorization-info/authorization-info.component";
import { CommonModule } from "@angular/common";
import { FieldChipsCompleteComponent } from "../field-chips-complete/field-chips-complete.component";
import { FieldChoicesComponent } from "../field-choices/field-choices.component";
import { FieldComponent } from "./field.component";
import { FieldDaterangepickerComponent } from "../field-daterangepicker/field-daterangepicker.component";
import { FieldHelpertooltipComponent } from "../field-helpertooltip/field-helpertooltip.component";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatRippleModule } from "@angular/material/core";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { NgModule } from "@angular/core";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";

@NgModule({
  declarations: [
    FieldComponent,
    FieldChipsCompleteComponent,
    FieldDaterangepickerComponent,
    FieldHelpertooltipComponent,
    FieldChoicesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    NgxMaterialTimepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatChipsModule,
    MatAutocompleteModule
  ],
  exports: [
    FieldComponent,
    FieldChoicesComponent
  ],
})
export class FieldModule {}
