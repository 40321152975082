import { Component, OnInit, EventEmitter, Optional } from "@angular/core";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-maxtries-content-modal",
  templateUrl: "./maxtries-content-modal.component.html",
  styleUrls: ["./maxtries-content-modal.component.scss"],
})
export class MaxtriesContentModalComponent implements OnInit {
  isCustomer;
  validateConfirmEvent: any = new EventEmitter();
  constructor(
    @Optional() public parentComponentInjectionObject: ModalComponent
  ) {}

  ngOnInit(): void {}

  isCustomerEvent($event, isCustomer) {
    this.isCustomer = isCustomer;
    this.parentComponentInjectionObject.validateConfirm({
      event: $event,
      confirmData: { isCustomer },
    });
  }
}
