import { FactoryProvider, InjectionToken } from '@angular/core';
import { WINDOW } from './window.provider';

interface IGoogleTaskManagerDataLayer {
  push: (data: object) => void;
}

const GoogleTaskManagerDataLayerFactory = (window: Window) => {
  return window['dataLayer'];
};

const GOOGLE_TASK_MANAGER_DATA_LAYER: InjectionToken<IGoogleTaskManagerDataLayer> = new InjectionToken<IGoogleTaskManagerDataLayer>('GOOGLE_TASK_MANAGER_DATA_LAYER');
const GoogleTaskManagerDataLayerProvider: FactoryProvider = {
  provide: GOOGLE_TASK_MANAGER_DATA_LAYER,
  useFactory: GoogleTaskManagerDataLayerFactory,
  deps: [WINDOW],
};
export { IGoogleTaskManagerDataLayer, GOOGLE_TASK_MANAGER_DATA_LAYER, GoogleTaskManagerDataLayerProvider };
