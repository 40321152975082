import { Component, Input } from "@angular/core";

@Component({
  selector: "app-slider-spinner",
  templateUrl: "./slider-spinner.component.html",
  styleUrls: ["./slider-spinner.component.scss"],
})
export class SliderSpinnerComponent {
  @Input() message;
  constructor() {}
}
