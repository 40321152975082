import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-modal-consent-of-uses",
  templateUrl: "./modal-consent-of-uses.component.html",
  styleUrls: ["./modal-consent-of-uses.component.scss"],
})
export class ModalConsentOfUsesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
