import { Observable, of, timer } from "rxjs";
import { delay, map } from "rxjs/operators";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from "../../environments/environment";

interface OtpPayload {
  info: PlanValues;
  id_offer: string;
}

interface PlanValues {
  insurance_value: number;
  product_plan_id: number;
  premium_value: number;
  net_premium: number;
  premium_value_year?: number;
  totalPremiums?: number[];
}

@Injectable({
  providedIn: "root",
})
export class OtpService {
  public data: any;
  private requestWCampaign: boolean = false;
  public canValidate = false;
  public isValidOTP = true;
  public defaultMsgError = "El código ingresado es incorrecto, reinténtelo.";
  public msgError = this.defaultMsgError;
  constructor(private httpClient: HttpClient) {}
  private cleanData(res) {
    this.setData(res.data);
    return this.getData();
  }
  public setNextRequestWCampaign(input) {
    this.requestWCampaign = input;
  }
  public getNextRequestWCampaign() {
    return this.requestWCampaign;
  }
  public setCanValidate(input) {
    this.canValidate = input;
  }
  public setIsValidOTP(input, msg = this.defaultMsgError) {
    this.isValidOTP = input;
    this.msgError = msg;
  }
  public getIsValidOTP() {
    return of({ isValidOTP: this.isValidOTP }).pipe(delay(400));
  }
  public getData() {
    return this.data;
  }
  public setData(data = null) {
    this.data = data;
  }
  sendOtpData(data: OtpPayload) {
    const url = "https://run.mocky.io/v3/dcb994d6-8131-45c2-a8eb-da8c26bb80a7";

    const premiumTotales: number[] = [];
    premiumTotales.push(data.info.premium_value);
    if (data.info.premium_value_year) {
      premiumTotales.push(data.info.premium_value_year);
    }
    data.info.totalPremiums = premiumTotales;
    return this.httpClient.post<any>(`${env.baseUrl}otp`, data).pipe(
    //return this.httpClient.post<any>(url, data).pipe(
      map((data) => {
        this.setNextRequestWCampaign(true);
        return this.cleanData(data);
      })
    );
  }
}
