<mat-form-field appearance="outline" class="w-100">
  <input [attr.data-cy]="'field-'+id" type="hidden" [value]="value" />
  <mat-label>{{label}}</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <input matStartDate name="start" formControlName="start" placeholder="Partida" (dateChange)="updateDateRangeValue($event, key)">
    <input matEndDate name="end" formControlName="end" placeholder="Regreso" (dateChange)="updateDateRangeValue($event, key)">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
