import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  Optional,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Field } from "@shared/field.model";
import { FieldControlService } from "@services/field-control.service";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
  providers: [FieldControlService],
})
export class FormComponent implements OnInit, OnChanges {
  @Input() description: string;
  @Input() formName: string;
  @Input() helperTooltip: any;
  @Input() fields: Field<any>[] = [];
  @Input() submitText: string;
  @Input() submitIcon: string;
  @Input() isDialog: boolean;
  @Input() noShowSubmit: boolean;
  @Input() disabledForm: boolean;
  @Input() currentInnerStep: number;
  @Input() consentOfUsesValid?: boolean = null;
  @Output() close = new EventEmitter<any>();
  //@Output() formRef: FormGroup;
  @Output() submit = new EventEmitter<any>();
  @Output() parentEvent = new EventEmitter<any>();
  @Output() changesEvent = new EventEmitter<any>();
  @Output() focusBlurEvent = new EventEmitter<any>();
  @Output() blurEvent = new EventEmitter<any>();
  @Output() updateGroupEvent = new EventEmitter<any>();
  @Output() customParentEvent = new EventEmitter<any>();
  @ViewChild("formchild", { static: false }) public formchild: ElementRef;
  @ViewChild("fieldset") public fieldset: ElementRef;
  form: UntypedFormGroup;
  payLoad = "";

  constructor(
    @Optional() public parentComponentInjectionObject: ModalComponent,
    private fcs: FieldControlService
  ) {}

  ngOnInit(): void {
    this.form = this.fcs.toFormGroup(this.fields);
    this.onChanges();
  }

  initFillForm(data) {
    this.form.patchValue(data);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fields) {
      this.form = this.fcs.toFormGroup(changes.fields.currentValue);
      this.form.updateValueAndValidity();
    }
  }
  onChanges(): void {
    var beforeChanges = { ...this.form.value };
    this.form.valueChanges.subscribe((formValues) => {
      var key = Object.keys(formValues).find(
        (k) => formValues[k] != beforeChanges[k]
      );
      this.changesEvent.emit({
        formName: this.formName,
        fieldName: key,
        formValid: this.form.root.valid,
        formValues,
      });
      beforeChanges = formValues;
    });
  }
  onBlur($event) {
    const obj = {
      formName: this.formName,
      fieldName: $event.target.name,
    };

    this.blurEvent.emit({ event: $event, ...obj });
  }
  onSubmit($event) {
    this.payLoad = this.form.value;
    this.parentEvent.emit({ event: $event, payload: this.payLoad });
  }
  onCancel() {
    this.close.emit(null);
  }
  triggerCustomEvent($event) {
    this.customParentEvent.emit($event);
    this.onEventDialog({ event: $event.event, message: $event.message });
  }
  onEventDialog(message) {
    this.parentComponentInjectionObject.openModalFromDynContainer(message);
  }
  updateValue($event) {
    if ($event.targetElement) {
      const objValue = {
        [$event.key]: {
          [$event.targetElement.name]: $event.value,
        },
      };
      this.form
        .get(`${$event.key}`)
        .setValue({ [$event.targetElement.name]: $event.value });
    } else {
      const key = Object.keys($event)[0];
      this.form.get(key).setValue($event[key]);
    }
  }
  updateValueGroup($event) {
    const obj = {
      formName: this.formName,
      fieldName: $event.targetElement.name,
      value: $event.value,
    };

    this.updateGroupEvent.emit({ event: $event, ...obj });
  }
}
