import { Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

import { Field } from "@shared/field.model";

@Injectable()
export class FieldControlService {
  constructor() {}

  toFormGroup(fields: Field<any>[]) {
    let group: any = {};
    fields.forEach((field) => {
      group[field.key] = field.group
        ? new UntypedFormGroup(field.value || "", {
            validators: Validators.compose(field.validations),
            asyncValidators: field.asyncValidations
              ? field.asyncValidations
              : null,
            updateOn: field.validateOn || "change",
          })
        : field.validations.length
        ? new UntypedFormControl(field.value || "", {
            validators: Validators.compose(field.validations),
            asyncValidators: field.asyncValidations.length
              ? field.asyncValidations
              : null,
            updateOn: field.validateOn || "change",
          })
        : !field.validations.length && field.asyncValidations.length
        ? new UntypedFormControl(field.value || "", {
            validators: null,
            asyncValidators: field.asyncValidations,
            updateOn: field.validateOn || "change",
          })
        : new UntypedFormControl(field.value || "");
    });
    return new UntypedFormGroup(group);
  }
}
