import { Component, OnInit, Renderer2 } from '@angular/core';

import { BaseComponent } from '@app/base/base.component';

@Component({
  selector: 'app-secure-layout',
  templateUrl: './secure-layout.component.html',
  styleUrls: ['./secure-layout.component.scss']
})
export class SecureLayoutComponent extends BaseComponent implements OnInit {

  constructor(
    public renderer: Renderer2
  ) { 
    super(renderer);
  }

  ngOnInit() {
    this.hideLoader();
  }

}
