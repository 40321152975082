export enum TravelConstants {
  openContactUsEvent = "openContactUs",
  openScheduleCallEvent = "openScheduleCall",
  utmTermParam = "utm_term",
  utmTermScheduleCall = "agenda_una_llamada",
  wifiCallSuffixUrl = "wifi-call",
  wifiCallEvent = "wifiCall",
  openTravelAssistanceEvent = "travelAssistance",
  closeTravelAssistanceEvent = "closeTravelAssistance",
  initWifiCallEvent = "initWifiCall",
  closeModalWifiCallEvent = "closeModalWifiCall",
  micOffWifiCallEvent = "micOffWifiCall",
  micOnWifiCallEvent = "micOnWifiCall",
  endWifiCallEvent = "endWifiCall",
  wifiCallActionLabel = "Llamar",
  defaultWifiCallStatus = "default",
  initWifiCallStatus = "init",
  inProgressWifiCallStatus = "inProgress",
  inProgressMutedWifiCallStatus = "inProgressMuted",
  startedWifiCallEvent = "started",
  cancelledWifiCallEvent = "cancelled",
  hangUpWifiCallEvent = "hangUp",
  ringingWifiCallEvent = "ringing",
  answeredWifiCallEvent = "answered",
  rejectedWifiCallEvent = "rejected",
  busyWifiCallEvent = "busy",
  timeoutWifiCallEvent = "timeout",
  failedWifiCallEvent = "failed",
  completedWifiCallEvent = "completed",
  agendaUnaLlamadaEvent = "Agenda una llamada",
  PRODUCT_NAME = "Seguro de viaje Travel",
  SUCCESS_MESSAGE = "Accepted",
  openMedicalExpensesInfoEvent = "openMedicalExpensesInfo",
  openCancelTripInfoEvent = "openCancelTripInfo",
  openLostBaggageInfoEvent = "openLostBaggageInfo",
  tripTypeDefault = "round-trip",
  nationalCountry = "Ecuador",
  nationalOption = "national",
  internationalOption = "international",
  tripDestinationDomestic = "Domestic",
  tripDestinationInternational = "International",
  goToHome = "goToHome",
  goToQuote = "goToQuote",
  OPEN_IS_CUSTOMER_EVENT = "openIsCustomer",
  addBeneficiaryEvent = "addBeneficiary",
  goToInsurance = "goToInsurance",
  linkEventType = "link",
  openDialogEvent = "openDialog",
  openCoveragesExclusionsEvent = "Open Coverage Dets & Exclusions",
  scheduleCallEvent = "Schedule Call",
  openCoveragesEvent = "openCoverages",
  editPersonalInfoAction = "editPersonalInfo",
  paymentFrequencyName = "Single",
  identificationLoginName = "Cédula",
  otpLoginName = "OTP",
  latamRegion = "LATAM",
  usRegion = "North America",
  rowRegion = "Rest of the World",
  schengenRegion = "Schengen",
  suffixLeadUrl = "datos-contacto"
}
