import { Component, HostBinding, OnInit } from "@angular/core";

import { ModalLegacyService } from "@app/services/modal-legacy.service";
import { ScheduleCallFieldService } from "./../../../services/schedule-call-field.service";

@Component({
  selector: "app-public-footer",
  templateUrl: "./public-footer.component.html",
  styleUrls: ["./public-footer.component.scss"],
})
export class PublicFooterComponent {
  @HostBinding("class") class = "legacy-container";
  fields: any[];
  submitSrvFn: any;
  sendLabel: string = "Agenda una llamada";
  constructor(
    service: ScheduleCallFieldService,
    private modalLegacyService: ModalLegacyService
  ) {
    this.fields = service.getFields();
  }

  titleTerms: string = "Términos y Condiciones";
  titleClick: string = "¿Necesitas ayuda? ¡Contáctanos!";

  openScheduleDialog(): void {
    this.modalLegacyService.sendModalClickToCall("ScheduleCall");
  }
}
