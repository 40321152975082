import { CheckboxField } from "@shared/field-checkbox";
import { DatePickerField } from "@shared/field-datepicker";
import { Field } from "@shared/field.model";
import { Injectable } from "@angular/core";
import { TextboxField } from "@shared/field-textbox";
import { TimePickerField } from "@shared/field-timepicker";
import { Validators } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class ScheduleCallFieldService {
  minDate: Date;
  maxDate: Date;
  minHour: string;
  allDupedDates: Date[];
  constructor() {
    this.minDate = new Date();
    this.maxDate = new Date(
      this.minDate.getFullYear(),
      this.minDate.getMonth(),
      this.minDate.getDate() + 20
    );

    this.allDupedDates = [
      new Date(`${this.minDate.getFullYear()}-01-01`),
      new Date(`${this.minDate.getFullYear()}-02-12`),
      new Date(`${this.minDate.getFullYear()}-02-13`),
      new Date(`${this.minDate.getFullYear()}-05-03`),
      new Date(`${this.minDate.getFullYear()}-05-24`),
      new Date(`${this.minDate.getFullYear()}-10-09`),
      new Date(`${this.minDate.getFullYear()}-11-01`),
      new Date(`${this.minDate.getFullYear()}-11-04`),
      new Date(`${this.minDate.getFullYear()}-12-25`),
    ];
  }

  weekendFilter = (d: Date): boolean => {
    const blockedDates = this.allDupedDates.map((d) => d?.valueOf());
    const day = d?.getDay();
    return !blockedDates.includes(d?.valueOf()) && day !== 0 && day !== 6;
  };

  getMinDate() {
    if (this.minDate.getHours() >= 17) {
      this.minDate.setDate(this.minDate.getDate() + 1);
    }
    return this.minDate;
  }

  getFields(payload = null) {
    let fields: Field<any>[] = [
      new TextboxField({
        key: "identification_number",
        id: "schedule-docId",
        label: "Cédula de identidad",
        value: "",
        required: true,
        validations: [Validators.required, Validators.pattern("[a-zA-Z0-9]+")],
        errorMessages: [
          {
            rule: "required",
            message: "Ingresa cédula de identidad",
          },
          {
            rule: "pattern",
            message: "Ingresa solo letras y números",
          },
        ],
        help: "En caso de que decidas adquirir este producto, utilizaremos este dato para activar un descuento por ser cliente de Banco Guayaquil",
        order: 3,
      }),

      new TextboxField({
        key: "fullName",
        id: "schedule-fullName",
        label: "Nombre completo",
        value: "",
        required: true,
        validations: [Validators.required],
        errorMessages: [
          {
            rule: "required",
            message: "Ingresa nombre completo",
          },
        ],
        order: 1,
      }),

      new TextboxField({
        key: "phone_number",
        id: "schedule-numTel",
        label: "Número telefónico",
        value: "",
        required: true,
        validations: [Validators.required, Validators.pattern("[0-9]{7,10}")],
        errorMessages: [
          {
            rule: "required",
            message: "Ingresa número telefónico",
          },
          {
            rule: "pattern",
            message: "Ingresa solo numeros entre 7 a 10 carácteres",
          },
        ],
        order: 2,
      }),

      new DatePickerField({
        key: "date_preference",
        id: "schedule-date",
        label: "Fecha de preferencia",
        value: "",
        config: {
          minDate: this.getMinDate(),
          maxDate: this.maxDate,
          filter: this.weekendFilter,
          touch: true,
        },
        order: 4,
      }),

      new TimePickerField({
        key: "hour_preference",
        id: "schedule-time",
        label: "Hora de preferencia",
        value: "",
        config: {
          minTime: "08:00 am",
          maxTime: "06:00 pm",
          hint: "Lunes a Viernes, de 08:00 a 18:00",
        },
        order: 5,
      }),
    ];

    return fields.sort((a, b) => a.order - b.order);
  }
}
