import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, Subject } from "rxjs";

import { Injectable } from "@angular/core";
import { SessionStorageService } from "@app/services/session-storage.service";
import { environment as env } from "@environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PortalService {
  private _validationMessageSource = new Subject<string>();
  validationMessage$ = this._validationMessageSource.asObservable();
  static readonly PORTAL_MODE_DEFAULT = "ECOMMERCE";
  static readonly PORTAL_MODE_CC = "CALLCENTER";
  public portalMode: string = PortalService.PORTAL_MODE_DEFAULT;
  public routeFlow: string = "";
  private data: any[];
  private plainData: any[];
  private subData: any[];
  private plainSubData: any[];
  public productsData: any[];
  private plainProductsData: any[];
  public categoryData;
  public subcategoryData;

  constructor(
    private httpClient: HttpClient,
    private _sessionSt: SessionStorageService
    ) {
      this.portalMode = this._sessionSt.getchubb_data()?.portalMode || PortalService.PORTAL_MODE_DEFAULT;
    }

  public cleanData(res) {
    const resData = res.data;
    this.portalMode = resData.Is_Call_Center
      ? "CALLCENTER"
      : this.portalMode == "F2F"
      ? "F2F"
      : "ECOMMERCE";
    this._sessionSt.set_SessionStorage({ name: "portalMode", value: this.portalMode });
    const list = [...res.data.List_Ramos];
    this.setData([...list.sort((a, b) => a.Ramo_Id - b.Ramo_Id)]);
    this.setPlainData(res.data);
    return this.getData();
  }
  public getData() {
    return this.data;
  }
  public setData(data): void {
    this.data = data;
  }
  public getPlainData() {
    return this.plainData;
  }
  public setPlainData(data): void {
    this.plainData = data;
  }

  public updatePortalMode(mode: string) {
    this.portalMode = mode;
    this._sessionSt.set_SessionStorage({ name: "portalMode", value: this.portalMode });
  }

  public cleanSubData(res) {
    if (Array.isArray(res.data)) {
      this.setSubData([...res.data]);
      this.setPlainSubData(res.data);
    } else {
      return this.cleanProductsData({ ...res, data: [res.data] });
    }
    return this.getSubData();
  }
  public getSubData() {
    return this.subData;
  }
  public setSubData(data): void {
    this.subData = data;
  }
  public getPlainSubData() {
    return this.plainSubData;
  }
  public setPlainSubData(data): void {
    this.plainSubData = data;
  }

  public cleanProductsDataToLanding(res) {
    return {
      products: [...this.cleanProductsData(res)],
      toLanding:
        res.data.length === 1 &&
        res.data[0].modal_Age === false &&
        res.data[0].url,
    };
  }

  public cleanProductsData(res) {
    this.setProductsData(res.data);
    this.setPlainProductsData(res.data);
    return this.getProductsData();
  }
  public getProductsData() {
    return this.productsData;
  }
  public getProductsFromSearchData() {
    return this.productsData;
  }
  public setProductsData(data) {
    this.productsData = data;
  }
  public setProductFromSearchData(data) {
    this.productsData = data;
    this.sendValidationMessage("Update products");
  }
  public getPlainProductsData() {
    return this.plainProductsData;
  }
  public setPlainProductsData(data): void {
    this.plainProductsData = data;
  }

  public setRouteFlow(input) {
    this.routeFlow = input;
  }
  public getRouteFlow() {
    return this.routeFlow;
  }

  public setCategoryData(input) {
    this.categoryData = { ...input };
  }
  public getCategoryData() {
    return this.categoryData;
  }
  public setSubcategoryData(input) {
    this.subcategoryData = { ...input };
  }
  public getSubcategoryData() {
    return this.subcategoryData;
  }

  getRootBranches(input: any) {
    return this.httpClient
      .post(`${env.baseUrl}homeproduct/getallramos`, input)
      .pipe(map((data) => this.cleanData(data)));
  }
  getSubBranches(input) {
    return this.httpClient
      .post(`${env.baseUrl}homeproduct/getcoveragesbyramo`, input)
      .pipe(map((data) => this.cleanSubData(data)));
  }
  getProductsFromBranch(input) {
    return this.httpClient
      .post(`${env.baseUrl}homeproduct/getproductsbycoverage`, input)
      .pipe(map((data) => this.cleanProductsData(data)));
  }
  getProductsFromBranchToLanding(input) {
    return this.httpClient
      .post(`${env.baseUrl}homeproduct/getproductsbycoverage`, input)
      .pipe(map((data) => this.cleanProductsDataToLanding(data)));
  }
  getProductsFromSearch(input): Observable<any> {
    return this.httpClient
      .post(`${env.baseUrl}homeproduct/getproductsbyname`, {
        productName: input,
      })
      .pipe(map((data: any) => data.data));
  }
  sendValidationMessage(message: string) {
    this._validationMessageSource.next(message);
  }
}
