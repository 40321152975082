import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocomplete as MatAutocomplete,
} from "@angular/material/legacy-autocomplete";
import { MatLegacyChipInputEvent as MatChipInputEvent } from "@angular/material/legacy-chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-field-chips-complete",
  templateUrl: "./field-chips-complete.component.html",
  styleUrls: ["./field-chips-complete.component.scss"],
})
export class FieldChipsCompleteComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  inputCtrl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  options: string[] = [];

  @Input() id: string;
  @Input() key: string;
  @Input() value: string[];
  @Input() allOptions: string[] = [];
  @Output() updateChoices = new EventEmitter();
  @ViewChild("childInput") childInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  constructor() {
    this.filteredOptions = this.inputCtrl.valueChanges.pipe(
      startWith(null),
      map((option: string | null) =>
        option ? this._filter(option) : this.allOptions.slice()
      )
    );
  }

  ngOnInit(): void {
    this.options = [...this.options, ...this.value];
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (this.allOptions.indexOf(value) !== -1) {
      if ((value || "").trim()) {
        this.options.push(value.trim());
        this.updateChoices.emit(event);
      }
    }

    if (input) {
      input.value = "";
    }

    this.inputCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.options.indexOf(fruit);

    if (index >= 0) {
      this.options.splice(index, 1);
      this.updateChoices.emit({ [this.key]: this.options });
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.options.push(event.option.viewValue);
    this.childInput.nativeElement.value = "";
    this.inputCtrl.setValue(null);
    this.updateChoices.emit({ [this.key]: this.options });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allOptions.filter(
      (fruit) => fruit.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
