import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment as env } from "../../environments/environment";

export enum ScheduleCallConstants {
  openContactUsEvent = "openContactUs",
  openScheduleCallEvent = "openScheduleCall",
  utmTermParam = "utm_term",
  utmTermScheduleCall = "agenda_una_llamada",
  agendaUnaLlamadaEvent = "Agenda una llamada"
}
export interface ScheduleCallModel {
  fullname: string;
  phone_number: string;
  identification_number: string;
  date_preference: Date;
  hour_preference: Date;
}

@Injectable({
  providedIn: "root",
})
export class ScheduleCallService {
  private _updateDialogState = new Subject<string>();
  updateDialogState$ = this._updateDialogState.asObservable();

  constructor(private httpClient: HttpClient, private datePipe: DatePipe) {}

  public sendDialogState(message: string) {
    this._updateDialogState.next(message);
  }

  /** Deprecated Method */
  public addScheduleCall(data: ScheduleCallModel): Observable<any> {
    return this.httpClient.post<any>(
      `${env.baseUrl}callscheduler/addscheduledcall`,
      {
        ...data,
        date_preference: data.date_preference?.toLocaleDateString(),
        hour_preference: this.datePipe.transform(
          data.hour_preference,
          "h:mm a"
        ),
      }
    );
  }
}
