import { CampaignService, LoaderService } from "@app/services";
import { Component, OnInit } from "@angular/core";

import { ProductPageRouteService } from "@app/services/product-page-route.service";
import { Subject } from "rxjs";

@Component({
  selector: "app-loader-nointerceptor",
  templateUrl: "./loader-nointerceptor.component.html",
  styleUrls: ["./loader-nointerceptor.component.scss"],
})
export class LoaderNointerceptorComponent implements OnInit {
  color = "primary";
  mode = "indeterminate";
  value = 50;
  message: string;
  isLoading: boolean = false;
  isLoadingSub: Subject<boolean> = this.loaderService.isLoadingNoInterceptor;

  public hideLayout: boolean = false;
  constructor(
    private loaderService: LoaderService,
    private campaignService: CampaignService,
    public productPageRouteService: ProductPageRouteService
  ) {
    this.message = this.campaignService.loaderMessageDefault;
    this.campaignService.loaderNoInterceptor$.subscribe((msg) => {
      this.isLoading = msg;
    });
    this.productPageRouteService.showOrHideLayout$.subscribe((msg) => {
      this.hideLayout = msg;
    });
  }

  ngOnInit(): void {}
}
