import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { CampaignService } from "@services/campaign.service";
import { LoaderService } from "@services/loader.service";
import { ProductPageRouteService } from "@app/services/product-page-route.service";
import { Subject } from "rxjs";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnChanges {
  color = "primary";
  mode = "indeterminate";
  value = 50;
  message: string;
  loaderInfo = {
    text: "Tenemos las mejores ofertas \n de seguro para ti.",
  };
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  callPay: Subject<boolean> = this.loaderService.callUsStatus;
  constructor(
    private loaderService: LoaderService,
    private campaignService: CampaignService
  ) {
    this.message = this.campaignService.loaderMessageDefault;
    this.campaignService.waitingMessage$.subscribe((msg) => {
      this.message = msg;
    });
  }
  ngOnChanges(changes: SimpleChanges) {}
}
