import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SessionStorageService {
  private defaultProps = {
    Partner: "BG",
    Market: "EC",
    language: "ES",
    partnerName: "Banco Guayaquil",
    partnerCountry: "Ecuador",
    goodsCategory: "insurance",
    currency: "USD",
    saleType: "VL",
    saleTypePrefixUrl: "vl-cliente",
  };
  public chubb_data: any = {
    //All variables need to be inserted here
    ...this.defaultProps,
    ...JSON.parse(sessionStorage.getItem("chubbData_front")),
  };
  constructor() {
    const SessionJSON = JSON.parse(sessionStorage.getItem("chubbData_front"));
    this.set_chubb_data(SessionJSON);
  }

  public set_chubb_data(input = null) {
    this.chubb_data = {
      ...this.chubb_data,
      ...input,
    };
  }
  public set_SessionStorage(field = null) {
    if (field) {
      this.set_chubb_data({ [field.name]: field.value });
    }
    sessionStorage.setItem("chubbData_front", JSON.stringify(this.chubb_data));
  }

  public set_SessionStorageFromObject(obj: object) {
    Object.entries(obj).map((kv) => {
      this.set_SessionStorage({
        name: kv[0],
        value: kv[1],
      });
    });
  }

  public getchubb_data() {
    return this.chubb_data;
  }

  public resetSessionData(saleStatus) {
    this.chubb_data = {
      ...this.defaultProps,
      ...saleStatus,
    };
    this.set_SessionStorage();
  }
  public getParamsObj() {
    let returnObj = {};
    if (this.chubb_data.saleType == "VS") {
      returnObj["o"] = this.chubb_data.offer_id;
    } else if (this.chubb_data.saleType == "VL") {
    } else {
      returnObj["operation_id"] = this.chubb_data.operation_id;
    }
    return returnObj;
  }
}
