import { Injectable, OnInit } from "@angular/core";

import { BehaviorSubject } from "rxjs";
import { Product } from "./../enums/product";
import { Router } from "@angular/router";
import { SessionStorageService } from "./session-storage.service";

export interface DataReload {
  dataParams?: string;
  planType?: string;
  planValues?: string;
  draftData?: string;
  otpData?: string;
  beneficiariesList?: any;
  paySuccess?: string;
  ageRange?: string;
  mailBox?:boolean;
  textMailBox?:boolean;
}

export interface PathStep {
  routeId: string;
  title: string;
  active: boolean;
}

export interface CurrentStep {
  routeId: string;
  title: string;
  order: number;
  length: number;
}

@Injectable({
  providedIn: "root",
})
export class DataService {
  data: DataReload = {};
  actualRouteId: string;
  private dataSource = new BehaviorSubject<DataReload>(this.data);
  constructor(
    private router: Router,
    private _sessionSt: SessionStorageService
  ) {}

  getData(product: Product): any {
    let data = this.dataSource.asObservable();
    let emptyData;
    data.subscribe((data) => {
      emptyData = Object.entries(data).length === 0;
      if (emptyData === true) {
        const paramsObj = this._sessionSt.getParamsObj();
        if (product === Product.HospiSeguro) {
          this.router.navigate(["/hospi-seguro"], { queryParams: paramsObj });
        } else if (product === Product.VidaSalud) {
          this.router.navigate(["/salud-vida-assist"], {
            queryParams: paramsObj,
          });
        } else if (product === Product.AutoContenido) {
          this.router.navigate(["/auto-contenido"], {
            queryParams: paramsObj,
          });
        }
      }
    });
    return data;
  }

  setDataPath() {}

  updatedDataSelection(data: DataReload) {
    this.dataSource.next(data);
  }
}
