import { HttpClient, HttpParams } from "@angular/common/http";

import { CampaignService } from "./campaign.service";
import { Injectable } from "@angular/core";
import { TravelConstants } from "@app/modules/travel/travel.constants";
import { environment as env } from "../../environments/environment";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CountriesService {
  private data: any[];
  private plainData: any[];

  constructor(
    private httpClient: HttpClient,
    private campaignService: CampaignService
  ) {}

  public cleanData(res) {
    res = res.filter(e => e.Name_ES !== TravelConstants.nationalCountry);
    this.setData([...res]);
    this.setPlainData(res);
    this.campaignService.setCatalogsData({
      countries: res,
    });
    return this.getData();
  }
  public getData() {
    return this.data;
  }
  public setData(data): void {
    this.data = data;
  }
  public getPlainData() {
    return this.plainData;
  }
  public setPlainData(data): void {
    this.plainData = data.map((data) => data.Name_ES);
  }
  getRemoteData() {
    const params = new HttpParams().append("International", "true");
    return this.httpClient
      .get(`${env.baseUrl}travel/countries`, {
        params,
      })
      .pipe(map((data) => this.cleanData(data)));
  }

  getCountries() {
    return this.httpClient
      .post(`${env.baseUrl}travel/countries`, {})
      .pipe(map((data) => this.cleanData(data)));
  }
}
