import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export interface SelectedData {
  mainCoverage: string;
  rangeAge: string;
  addSpouse: boolean;
  spouse: boolean;
  spouseAmount: number;
  mailboxCard: boolean;
  benefitLevel: any;
}

@Injectable({
  providedIn: "root",
})
export class SelectedDataService {
  private _dataSelectedSource = new Subject<any>();
  dataSelected$ = this._dataSelectedSource.asObservable();
  constructor() {}

  getSelectedDataFromLocalStorage(): SelectedData {
    return localStorage.getItem("selectedData") === null ||
      localStorage.getItem("selectedData") === ""
      ? {}
      : JSON.parse(localStorage.getItem("selectedData"));
  }

  getSelectedDataInLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  saveSelectedDataInLocalStorage(selectedData: any): void {
    localStorage.setItem("selectedData", JSON.stringify(selectedData));
  }

  saveProductSelectedDataInLocalStorage(key: string, selectedData: any): void {
    localStorage.setItem(key, JSON.stringify(selectedData));
  }

  removeProductSelectedDataInLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  sendDataSelectedMessage(productKey:string, message: any, update:boolean = true) {
    this._dataSelectedSource.next(message);
    if (update) {
      this.saveProductSelectedDataInLocalStorage(productKey, message);
    }
  }
}
