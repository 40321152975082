import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  Inject,
  Input,
  Optional,
  EventEmitter,
  Output,
  Injector,
  ElementRef,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FieldControlService } from "@services/field-control.service";
import { Field } from "@shared/field.model";
import { TitleModalConfig } from "@shared/title-modal-config";
import { Plan } from "@shared/plan";
import { CampaignService } from "@services/campaign.service";
import { CustomerInfoService } from "@services/customer-info.service";
import { OtpService } from "@services/otp.service";
import { HttpClient } from "@angular/common/http";
import { SendgridService } from "@services/sendgrid.service";
import { ProductService } from "@services/product.service";
import { DomSanitizer } from "@angular/platform-browser";
import { CookieService } from "ngx-cookie-service";
import { SessionStorageService } from "@app/services/session-storage.service";
import { GoogleAnalyticServiceService } from "@app/helpers/google-analytic-service.service";
import { ModalConsentOfUsesComponent } from "../modal-consent-of-uses/modal-consent-of-uses.component";
import { ModalLegacyService } from "@app/services/modal-legacy.service";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: [
    "./modal.component.scss",
    "../../../assets/scss/modal.legacy.scss",
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [FieldControlService],
})
export class ModalComponent implements OnInit, AfterViewInit {
  @ViewChild("content", { read: ViewContainerRef }) content: ViewContainerRef;
  titleConfig: TitleModalConfig;
  injector: Injector;
  @Input() description: string;
  @Input() formName: string;
  @Input() fields: Field<any>[] = [];
  @Input() submitFn: Function;
  @Input() submitSrvFn: any;
  @Input() submitText: string;
  @Input() confirmTitle: string;
  @Input() confirmText: string;
  @Input() confirmHTML: string;
  @Input() bottomText: string;
  private _confirmRawHTML: string;
  @Input() confirmActions: ConfirmActions;
  @Input() icon: string;
  @Input() bgclass: string;
  @Input() emphasisTitle: string;
  @Input() additionalTitle: string;
  @Input() noShowClose: boolean;
  @Input() pdfShow: boolean;
  @Output() parentEvent = new EventEmitter<any>();
  @ViewChild("dialogContainer")
  parentClose: EventEmitter<any> = new EventEmitter();
  componentRef: ComponentRef<any>;
  fieldsData;
  confirmData;
  saleObject;
  campaignData;
  customerData;
  otpData;
  isCallCenter;
  constructor(
    private campaignService: CampaignService,
    private sendgridService: SendgridService,
    private productService: ProductService,
    private googleAnalyticService: GoogleAnalyticServiceService,
    public customerService: CustomerInfoService,
    public otpService: OtpService,
    public cookieService: CookieService,
    public _sessionSt: SessionStorageService,
    public dialogRef: MatDialogRef<ModalComponent>,
    private domSanitizer: DomSanitizer,
    private elementRef: ElementRef,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Optional() private httpClient: HttpClient,
    @Optional() private fcs: FieldControlService,
    @Optional() private inj: Injector
  ) {
    this.description = this.data.description;
    this.formName = this.data.formName;
    this.fields = this.data.fields;
    this.submitFn = this.data.submitFn;
    this.submitSrvFn = this.data.submitSrvFn;
    this.submitText = this.data.submitText;
    this.confirmTitle = this.data.confirmTitle;
    this.confirmText = this.data.confirmText;
    this.confirmHTML = this.data.confirmHTML;
    this._confirmRawHTML = this.confirmHTML;
    this.bottomText = this.data.bottomText;
    this.confirmActions = this.data.confirmActions;
    this.isCallCenter = this.data?.isCallCenter;
    this.titleConfig = this.data.configTitle;
    this.injector = Injector.create(
      [
        {
          provide: TitleModalConfig,
          useValue: new TitleModalConfig(this.titleConfig),
        },
      ],
      this.inj
    );
  }
  createInjector(titleConfig) {
    let injector = Injector.create(
      [{ provide: TitleModalConfig, useValue: titleConfig }],
      this.inj
    );
    return injector;
  }

  ngOnInit(): void {
    this.campaignData = this.campaignService.getData();
    this.saleObject = this.campaignService.getSaleStatus();
    this.customerData = this.customerService.getData();
    this.otpData = this.otpService.getData();
  }

  ngAfterViewInit(): void {
    if (this.elementRef.nativeElement.querySelector("#consent-of-uses-modal")) {
      this.elementRef.nativeElement
        .querySelector("#consent-of-uses-modal")
        .addEventListener("click", this.openModalConsentOfUsesEvent.bind(this));
    }
  }

  public get confirmRawHTML() {
    return this.domSanitizer.bypassSecurityTrustHtml(this._confirmRawHTML);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  closeModalFromDynContainer(message) {
    this.dialogRef.close(message);
  }

  openModalFromDynContainer(message) {
    this.dialogRef.componentInstance.parentEvent.emit(message);
  }

  changesForm($event) {
    this.fieldsData = $event.formValues;

    this.parentEvent.emit({
      event: $event,
      message: "updateFormValues",
      data: $event.formValues,
    });
  }

  closeAlert($event) {
    this.otpService.setIsValidOTP(true);
  }

  validateConfirm($event) {
    this.confirmData = $event.confirmData;
    this.confirmActions.confirmDisabled = false;
  }

  async submitOnDialog($event) {
    const request = await this.dialogRef.componentInstance.submitFn(
      $event.payload
    );
    if (request) {
      this.campaignService
        .sendWaitingMessage(`Estamos recibiendo tu información, en unos minutos podrás confirmar tus datos antes de comprar.
Recuerda, estamos para servirte.

Chubb siempre contigo.`);
      request.subscribe(
        (data) => {
          this.campaignService.loaderMessage =
            this.campaignService.loaderMessageDefault;
          this.dialogRef.close({
            message: "success",
            data: data?.data ? data.data : data,
          });
          return true;
        },
        (err) => {
          console.error("Error Modal Request:", err);
          const error = err.error;
          if (error.errorCode == "008.0001" || error.errorCode == "008.0005") {
            this.parentEvent.emit({
              message: "expiredCode",
              data: {
                ...error,
                ...$event.payload,
              },
            });
          } else if (
            error.errorCode == "100" ||
            error.errorCode == "008.0006"
          ) {
            this.parentEvent.emit({
              message: "updateValidation",
              data: {
                ...error,
                ...$event.payload,
              },
            });
          } else {
            this.parentEvent.emit({
              message: "unknownError",
              data: {
                ...error,
                ...$event.payload,
              },
            });
          }

          return false;
        }
      );
    } else {
    }
  }

  openModalConsentOfUsesEvent() {
    this.dialog.open(ModalComponent, {
      id: "dialog-consent-of-uses",
      backdropClass: "backdrop-blur",
      panelClass: "panel-mobile",

      minHeight: "80vh",
      maxHeight: "100vh",
      data: {
        title: "Tratamiento de Uso de Datos",
        component: ModalConsentOfUsesComponent,
        confirmActions: {
          confirmLabel: "Entiendo",
        },
      },
    });
  }
}

export interface DialogData {
  description: string;
  title?: string;
  titleComponent?: Component;
  dataCy?: string;
  component?: Component;
  titleBlock?: any;
  dataNoCode?: string;
  dataThanks?: string;
  dataActDisc?: string;
  formName: string;
  fields: Field<any>[];
  submitFn?: Function;
  submitSrvFn?: any;
  submitText: string;
  dataMaxNum?: number;
  dataSorry?: string;
  confirmTitle?: string;
  confirmText?: string;
  confirmHTML?: string;
  confirmActions?: ConfirmActions;
  configTitle?: TitleModalConfig;
  configPlan?: Plan;
  noShowClose?: boolean;
  customData?: any;
  pdfShow?: boolean;
  bottomText?: string;
  isCallCenter?: boolean;
}

interface ConfirmActions {
  confirmLabel?: string;
  confirmIcon?: string;
  confirmDisabled?: boolean;
  cancelLabel?: string;
}
