import { CommonModule } from '@angular/common';
import { LoaderComponent } from '@app/components/loader/loader.component';
import { LoaderNointerceptorComponent } from '@app/components/loader-nointerceptor/loader-nointerceptor.component';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { NgModule } from '@angular/core';
import { SimpleLoaderComponent } from '@app/modules/simple-loader/simple-loader.component';
import { SimpleLoaderVectorComponent } from '@app/modules/simple-loader/components/simple-loader-vector/simple-loader-vector.component';
import { SliderSpinnerComponent } from '@app/components/slider-spinner/slider-spinner.component';

@NgModule({
  declarations: [
    LoaderComponent,
    LoaderNointerceptorComponent,
    SimpleLoaderComponent,
    SimpleLoaderVectorComponent,
    SliderSpinnerComponent,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule
  ],
  exports: [
    LoaderComponent,
    LoaderNointerceptorComponent
  ]
})
export class LoaderModule { }
