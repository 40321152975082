export function calculateAgeInYears(date: Date) {
  var now = new Date();
  var current_year = now.getFullYear();
  var year_diff = current_year - date.getFullYear();
  var birthday_this_year = new Date(
    current_year,
    date.getMonth(),
    date.getDate()
  );
  var has_had_birthday_this_year = now >= birthday_this_year;

  return has_had_birthday_this_year ? year_diff : year_diff - 1;
}

export function isObject(val) {
  return typeof val === "object" && !Array.isArray(val) && val !== null;
}

export function replaceSpaces(string, char = "-") {
  return string.replace(/ /g, char);
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function isString(input) {
  return typeof input === "string";
}

export function percentage(num, per) {
  return (num / 100) * per;
}
