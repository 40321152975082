import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-base',
  template: ``,
  styles: [
  ]
})
export class BaseComponent {

  constructor(public renderer: Renderer2) { }

  hideLoader() {
    let loader = this.renderer.selectRootElement('#loader');
    if (loader)
      this.renderer.setStyle(loader, 'display', 'none');
  }

}
