import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'chubb-components-simple-loader',
  templateUrl: './simple-loader.component.html',
  styleUrls: ['./simple-loader.component.scss'],
})
export class SimpleLoaderComponent implements OnInit {
  @Input()
  data: any;
  constructor() {}

  ngOnInit(): void {}
}
