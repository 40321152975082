import { CampaignService, ErrorListService, ProductService, ScheduleCallFieldService, SendgridService } from '@app/services';
import { Component, OnInit } from '@angular/core';

import { CommonModalClickToCallComponent } from '../common-modal-click-to-call/common-modal-click-to-call.component';
import { Events } from '@app/enums/events.enum';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';
import { ModalLegacyService } from '@app/services/modal-legacy.service';
import { ResponseTitleComponent } from '../response-title/response-title.component';
import { Subscription } from 'rxjs';
import { TitleModalConfig } from '@app/shared/title-modal-config';

@Component({
  selector: 'app-modal-legacy',
  templateUrl: './modal-legacy.component.html',
  styleUrls: ['./modal-legacy.component.scss']
})
export class ModalLegacyComponent implements OnInit {
  saleStatus;
  opId: string;
  fields: any[];
  submitSrvFn;
  currentRoute: string;
  useOperation: boolean = false;
  layout: string = 'legacy';
  pageData: any = null;
  subscription: Subscription;
  successEvent: string = "success";
  errorEvent: string = "unknownError";
  scheduleCallEvent: string = "Schedule Call";
  constructor(
    service: ScheduleCallFieldService,
    private sendgridService: SendgridService,
    private campaignService: CampaignService,
    private productService: ProductService,
    private errorListService: ErrorListService,
    public dialog: MatDialog,
    private modalLegacyService: ModalLegacyService
  ) {
    this.fields = service.getFields();
    this.subscription = this.modalLegacyService.modalClickToCall$.subscribe((msg) => {
      if (msg == Events.ClickToCallEvent)
        this.openDialog();
      if (msg == Events.ScheduleCallEvent)
        this.openScheduleDialog();
        if (msg == Events.ScheduleCallAlwaysEvent)
        this.openScheduleDialog(true);
    });
  }

  ngOnInit(): void {
    this.saleStatus = this.campaignService.getSaleStatus();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      id: "dialog-click-to-call",
      autoFocus: false,
      backdropClass: "backdrop-blur",
      panelClass: "panel-mobile",
      minHeight: "80vh",
      maxHeight: "100vh",
      data: {
        title: "¿Necesitas ayuda? ¡Contáctanos!",
        component: CommonModalClickToCallComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == this.scheduleCallEvent) {
        this.openScheduleDialog();
      }
    });
  }

  openScheduleDialog(alwaysOpen = false): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      id: "dialog-schedule-call",
      autoFocus: false,
      backdropClass: "backdrop-blur",
      panelClass: "panel-mobile",
      minHeight: "80vh",
      maxHeight: "100vh",
      disableClose: alwaysOpen,
      data: {
        title: "Agenda una llamada",
        noShowClose: alwaysOpen,
        description:
          "Proporciona tus datos y pronto nos pondremos en contacto:",
        formName: "schedule-call-form",
        fields: this.fields,
        bottomText:
          '<p>Al presionar "Enviar", declaro haber leído y acepto el contenido de la cláusula de <a id="consent-of-uses-modal" href="javascript:void(0)" (click)="openModalConsentOfUses()">Tratamiento de Uso de Datos</a> de Chubb Seguros.</p>',
        submitFn: this.sendEmail,
        submitSrvFn: this.sendgridService.sendEmailData,
        submitText: "Enviar",
      },
    });
    let schedulePayload = {};

    dialogRef.componentInstance.parentEvent.subscribe((result) => {
      //TODO change date_preference
      const checkToday = () => {
        const todayDate = new Date();
        const datePreference = result.data?.date_preference;
        const todayString = `${todayDate.getFullYear()}-${
          todayDate.getMonth() + 1
        }-${todayDate.getDate()}`;
        const date = datePreference
          ? `${datePreference.getFullYear()}-${
              datePreference.getMonth() + 1
            }-${datePreference.getDate()}`
          : datePreference;
      };
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.message == this.successEvent) {
        this.openScheduleResponse(result);
      } else if (result?.message == this.errorEvent) {
        this.errorListService.openModalError();
      }
    });
  }

  openScheduleResponse(typeRes): void {
    var configTitle: TitleModalConfig;
    if (typeRes.message === this.successEvent) {
      configTitle = {
        bgclass: this.successEvent,
        icon: "check_circle_outline",
        emphasisTitle: "Hemos recibido tu solicitud para agendar una llamada",
      };
    }
    const dialogRef = this.dialog.open(ModalComponent, {
      id: "dialog-schedule-success",
      autoFocus: false,
      backdropClass: "backdrop-blur",
      panelClass: "panel-mobile",
      minHeight: "80vh",
      maxHeight: "100vh",

      data: {
        titleComponent: ResponseTitleComponent,
        configTitle,
        confirmText:
          "Uno de nuestros agentes se pondrá en contacto contigo lo antes posible.",
        confirmActions: {
          confirmLabel: "Aceptar",
        },
      },
    });
  }

  public sendEmail(input) {
    const campaignData = this.campaignService.getData();
    var dataToSend = {
      ...input,
      product: campaignData?.product_id
        ? this.productService.getProductByName(campaignData.product_id)?.label
        : "",
    };
    return this.submitSrvFn(dataToSend);
  }

}
