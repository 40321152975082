import {
  ActivatedRoute,
  ActivationEnd,
  ActivationStart,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadStart,
  Router,
} from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { filter, map, mergeMap } from "rxjs/operators";

import { CampaignService } from "./services/campaign.service";
import { DomSanitizer } from "@angular/platform-browser";
import { GoogleAnalyticServiceService } from "./helpers/google-analytic-service.service";
import { IRouteSettings } from "@helpers/iroute-settings";
import { LoaderService } from "./services";
import { MatIconRegistry } from "@angular/material/icon";
import { PortalService } from "./modules/home/services/portal.service";
import { ProductService } from "@services/product.service";
import { SessionStorageService } from "./services/session-storage.service";
import { StyleService } from "./services/style/style.service";

@Component({
  selector: "app-root",
  template: `<router-outlet></router-outlet>`,
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "app";
  private currentRouteData: IRouteSettings;
  private lastRouteData: IRouteSettings;
  tagManagerSessionId: any;

  campaignData;
  idOffer: string;
  queryParams;
  dataObj: any = null;
  hasNavigated: boolean = false;
  currentURL: string;
  previousURL: string;
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private googleAnalyticService: GoogleAnalyticServiceService,
    private campaignService: CampaignService,
    private productService: ProductService,
    private portalService: PortalService,
    private _sessionSt: SessionStorageService,
    public styleService: StyleService
  ) {
    this.styleService.load('bootstrap').then(data => {
    }).catch(error => console.log(error));
    const iconsList = [
      {
        icon: "click_to_call",
        src: "click-to-call",
      },
      {
        icon: "call_center",
        src: "call-center",
      },
      {
        icon: "envelope",
        src: "envelope",
      },
      {
        icon: "whatsapp",
        src: "whatsapp",
      },
      {
        icon: "app-store",
        src: "app-store",
      },
      {
        icon: "business",
        src: "business",
      },
      {
        icon: "broken_cell_phone",
        src: "broken-cell-phone",
      },
      {
        icon: "easy_cell_phone",
        src: "easy-cell-phone",
      },
      {
        icon: "no_paper_work",
        src: "no-paper-work",
      },
      {
        icon: "stole",
        src: "stole",
      },
      {
        icon: "shredded_paper",
        src: "shredded-paper",
      },
      {
        icon: "email_white",
        src: "email-white",
      },
      {
        icon: "phone",
        src: "phone-bg",
      },
      {
        icon: "check",
        src: "check",
      },
      {
        icon: "continuar",
        src: "continuar",
      },
      {
        icon: "envelope-white",
        src: "envelope-white",
      },
      {
        icon: "click_to_call-white",
        src: "click-to-call-white",
      },
      {
        icon: "whatsapp-white",
        src: "whatsapp-white",
      },
      {
        icon: "basic-plan",
        src: "umbrella_icon",
      },
      {
        icon: "standard-plan",
        src: "star-shine_icon",
      },
      {
        icon: "premium-plan",
        src: "diamond_icon",
      },
      {
        icon: "rip_icon",
        src: "rip-black",
      },
      {
        icon: "first-aid_icon",
        src: "assist-med-black",
      },
      {
        icon: "feather-calendar_icon",
        src: "feather-calendar",
      },
      {
        icon: "clinic-history_icon",
        src: "clinic-history",
      },
      {
        icon: "discount-badge_icon",
        src: "discount-badge",
      },
      {
        icon: "dentist-tools_icon",
        src: "dentist-tools",
      },
      {
        icon: "tooth_icon",
        src: "tooth",
      },
      {
        icon: "xmas-tree_icon",
        src: "xmas-tree",
      },
      {
        icon: "discount-ticket_icon",
        src: "discount-ticket",
      },
      {
        icon: "profilaxis_icon",
        src: "profilaxis",
      },
      {
        icon: "history_icon",
        src: "history",
      },
      {
        icon: "calendar_icon",
        src: "calendar",
      },
      {
        icon: "promotion_icon",
        src: "promotion-icon",
      },
      {
        icon: "navidad_icon",
        src: "navidad",
      },
      {
        icon: "radar",
        src: "radar",
      },
      {
        icon: "repair",
        src: "repair",
      },
      {
        icon: "keys",
        src: "keys",
      },
      {
        icon: "shield",
        src: "shield",
      },
      {
        icon: "time",
        src: "time",
      },
      {
        icon: "svgcontactanos",
        src: "svgcontactanos",
      },
      {
        icon: "svgcontactanoscircle",
        src: "svgcontactanoscircle",
      },
      {
        icon: "svgplus",
        src: "svgplus",
      },
      {
        icon: "svgclose",
        src: "svgclose",
      },
      {
        icon: "svgshield",
        src: "svgshield",
      },
      {
        icon: "svgcheck",
        src: "svgcheck",
      },
      {
        icon: "svgtabla",
        src: "svgtabla",
      },
      {
        icon: "svgcamilla",
        src: "svgcamilla",
      },
      {
        icon: "svgcorazon",
        src: "svgcorazon",
      },
      {
        icon: "svgplane",
        src: "svgplane",
      },
      {
        icon: "svgbreakmobil",
        src: "svgbreakmobil",
      },
      {
        icon: "svgprotectionpayment",
        src: "svgprotectionpayment",
      },
      {
        icon: "svgpuppy",
        src: "svgpuppy",
      },
      {
        icon: "svgheadphone",
        src: "svgheadphone",
      },
      {
        icon: "svgphone",
        src: "svgphone",
      },
      {
        icon: "svgwhatsapp",
        src: "svgwhatsapp",
      },
      {
        icon: "svgmail",
        src: "svgmail",
      },
    ];
    iconsList.forEach((el) => {
      this.matIconRegistry.addSvgIcon(
        el.icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `../assets/svg/${el.src}.svg`
        )
      );
    });
    this.matIconRegistry.addSvgIcon('svgcontactanos', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgcontactanos.svg'));
    this.matIconRegistry.addSvgIcon('svgplus', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgplus.svg'));
    this.matIconRegistry.addSvgIcon('svgclose', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgclose.svg'));
    this.matIconRegistry.addSvgIcon('svgshield', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/icons/paso_icon.svg'));
    this.matIconRegistry.addSvgIcon('svgcheck', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgcheck.svg'));

    this.matIconRegistry.addSvgIcon('svgtabla', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgtabla.svg'));
    this.matIconRegistry.addSvgIcon('svgcamilla', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgcamilla.svg'));
    this.matIconRegistry.addSvgIcon('svgcorazon', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgcorazon.svg'));
    this.matIconRegistry.addSvgIcon('svgplane', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgplane.svg'));
    this.matIconRegistry.addSvgIcon('svgbreakmobil', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgbreakmobil.svg'));
    this.matIconRegistry.addSvgIcon('svgprotectionpayment', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgprotectionpayment.svg'));
    this.matIconRegistry.addSvgIcon('svgpuppy', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgpuppy.svg'));

    this.matIconRegistry.addSvgIcon('svgheadphone', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgheadphone.svg'));
    this.matIconRegistry.addSvgIcon('svgphone', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgphone.svg'));
    this.matIconRegistry.addSvgIcon('svgwhatsapp', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgwhatsapp.svg'));
    this.matIconRegistry.addSvgIcon('svgmail', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svgmail.svg'));

    this.matIconRegistry.addSvgIcon('svInyeccion', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/inyeccion.svg'));
  }

  ngOnInit() {
    this.idOffer = this.activatedRoute.snapshot.queryParams?.o;
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        this.campaignService.setTryingUrl(evt.url);
      }
      if (evt instanceof ActivationEnd) {
        if (evt.snapshot.data && Object.keys(evt.snapshot.data).length) {
          this.dataObj = {
            ...this.dataObj,
            ...evt.snapshot.data,
          };
          this.loaderService.showModular = evt.snapshot.data?.isModular;
        }
      }
      if (evt instanceof NavigationEnd) {
        this.previousURL = this.currentURL;
        this.currentURL = evt.url;
        this.campaignService.setCurrentUrl(this.currentURL);
        this.campaignService.setPreviousUrl(this.previousURL);
        if (!this.hasNavigated && this.dataObj?.isLanding) {
          this.hasNavigated = true;
        }
        if (this?.dataObj) {
          this.sendAppScreenView(this.dataObj);
        }
        this.hasNavigated = true;
      } else {
        window.scrollTo(0, 0);
        return;
      }
      window.scrollTo(0, 0);
    });

    this.preventPullToRefresh("body");
    const hasSession = sessionStorage.getItem("chubbData_front");
    if (!hasSession) {
      this._sessionSt.set_SessionStorage();
    } else {
      const hasSessionJSON = JSON.parse(hasSession);
    }
  }

  private redirectAfterRefresh(data) {
    if (data.saleType == "VL") {
      if (location.search) {
        return this.router.navigateByUrl(`/${location.search}`);
      }
    } else if (data.saleType == "VS") {
      return this.router.navigate(["home"], {
        queryParams: { o: data["offer_id"] },
      });
    } else {
      return this.router.navigate(["home"], {
        queryParams: { operation_id: data["operation_id"] },
      });
    }
  }

  private preventPullToRefresh(element) {
    var prevent = false;

    document
      .querySelector(element)
      .addEventListener("touchstart", function (e) {
        if (e.touches.length !== 1) {
          return;
        }

        var scrollY =
          window.pageYOffset ||
          document.body.scrollTop ||
          document.documentElement.scrollTop;
        prevent = scrollY === 0;
      });

    document.querySelector(element).addEventListener("touchmove", function (e) {
      if (prevent) {
        prevent = false;
        e.preventDefault();
      }
    });
  }

  private sendAppScreenView(data) {
    if (data.title && data.path) {
      let compRoute = data.path;
      const prefixUrl = this.campaignService.getSaleStatus().prefixUrl;
      if (compRoute.includes(CampaignService.PREFIX_SALE.VL)) {
        if (prefixUrl !== CampaignService.PREFIX_SALE.VL) {
          compRoute = compRoute.replace(
            CampaignService.PREFIX_SALE.VL,
            prefixUrl
          );
        }
      }
      const productTrackGA = {
        event: "appScreenView",
        screenName: data.title,
        screenPath: compRoute,
        typeSale: this.campaignService.getSaleStatus()?.type || "VL",
        flow_type: prefixUrl || CampaignService.PREFIX_SALE.VL,
        portalMode: this.portalService.portalMode || "ECOMMERCE",
      };
      this.googleAnalyticService.googleTaskManagerPush(productTrackGA);
    }
    this.dataObj = null;
  }

  private composeRouteProduct(productUrl, route) {
    if (route.startsWith("/producto") && productUrl) {
      return route.replace("/producto", productUrl);
    }
    return route;
  }

  private setLastRouteFlow(productRouteFlow, restRouteFlow) {
    let fullRouteFlow = productRouteFlow;
    if (restRouteFlow == "/select-plan") {
      fullRouteFlow += restRouteFlow;
    } else if (restRouteFlow == "/confirm-info") {
      fullRouteFlow += `/select-plan${restRouteFlow}`;
    } else if (restRouteFlow == "/confirmacion-pago") {
      fullRouteFlow += `/select-plan/confirm-info${restRouteFlow}`;
    }
    return fullRouteFlow;
  }

  private getCurrentRouteData() {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => {
          this.idOffer =
            this.activatedRoute.snapshot.queryParams.o ||
            this.activatedRoute.snapshot.queryParams["operation_id"];
          this.queryParams = this.activatedRoute.snapshot.queryParams;
          return route.data;
        })
      )
      .subscribe((data: any) => {
        if (data.path === "/confirm-info") {
          return;
        }
        const routesToCheck = [
          "/previous-steps",
          "/select-plan",
          "/confirmacion-pago",
        ];
        this.campaignData = this.campaignService.getData();
        let productInfo = this.productService.getProductByName(
          this.campaignData?.product_id
        );
        if (!productInfo) {
          productInfo = this.productService.getProductByURLStarts(
            data?.path || ""
          );
        }
        let compRouteProduct = productInfo ? productInfo.url : "";
        let compRoute = data.path;
        compRouteProduct +=
          productInfo?.prevSteps && !productInfo?.customQuote
            ? "/previous-steps"
            : "";
        /*if (routesToCheck.includes(data.path)) {
          compRoute = this.setLastRouteFlow(compRouteProduct, data.path);
          this.campaignService.setRouteFlow(compRoute);
        } else*/ if (compRoute.includes(CampaignService.PREFIX_SALE.VL)) {
          const prefixUrl = this.campaignService.getSaleStatus().prefixUrl;
          if (prefixUrl !== CampaignService.PREFIX_SALE.VL) {
            compRoute = compRoute.replace(
              CampaignService.PREFIX_SALE.VL,
              prefixUrl
            );
          }
        }
        const productTrackGA = {
          event: "appScreenView",
          screenName: data.title,
          screenPath: compRoute,
          typeSale: this.campaignService.getSaleStatus()?.type || "VL",
          flow_type:
            this.campaignService.getSaleStatus()?.prefixUrl ||
            CampaignService.PREFIX_SALE.VL,
          portalMode: this.portalService.portalMode || "ECOMMERCE",
        };
        this.googleAnalyticService.googleTaskManagerPush(productTrackGA);
        this.lastRouteData = this.currentRouteData;
      });
  }
}
