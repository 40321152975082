import { Component, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";

import { Events } from "@app/enums/events.enum";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-common-modal-click-to-call",
  templateUrl: "./common-modal-click-to-call.component.html",
  styleUrls: ["./common-modal-click-to-call.component.scss"]
})
export class CommonModalClickToCallComponent {
  title: string = "Agenda una llamada";
  fields: any[];

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalComponent>
  ) {}

  openScheduleCall($e: Event): void {
    $e.preventDefault();
    this.dialogRef.close(Events.ScheduleSpaceCallEvent);
  }
}
