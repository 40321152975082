<div class="customer-content">
  <div class="d-flex flex-column justify-content-center">
    <p class="message">
      Por ahora no podemos procesar tu contratación en línea.
    </p>
    <p class="message">
      Si quieres puedes continuar tu contratación con asistencia telefónica
    </p>
    <div class="d-flex flex-column flex-lg-row justify-content-around mb-3">
      <a mat-button class="call-us-btn mt-3" color="primary" href="tel:02-395-7731">
        <mat-icon svgIcon="click_to_call"></mat-icon> Llámanos
      </a>
      <p class="message call-us mt-3 d-flex flex-column-reverse"><a href="/agenda-una-llamada">Agendar una llamada</a>
      </p>
    </div>
  </div>
</div>
