export const environment = {
  production: true,
  baseUrl: "/",
  gtmTrackingId: "GTM-TKGDZZJ",
  accountKey:"ECU-BG",
  isoCountryCode:'ECU',
  productCode:'TRAVEL',
  proxyUrl:'https://digitalproxy-uat.chubb.com/proxy/ecbangutravel2p',
  sourceLandingPageUrl:'bgseguros.chubb.com/travel',
  proxyVersion: "2",
  hospia: "ECH0900087",
  hospim: "ECH0900086",
  saludassistyear: "ECH0900001",
  saludassistmonth: "ECH0900005",
  saludassistyearName: "SALUDA",
  saludassistmonthName: "SALUME",
  sevim1: "ECH0900008",
  glggp4: "ECH0900007",
  vidaex: "ECH0900084",
  viment: "ECH0900083",
  celanu: "ECH0900013",
  celula: "ECH0900012",
  cerome: "ECH0900074",
  cedame: "ECH0900075",
  celcom: "ECH0900076",
  ceroan: "ECH0900098",
  cedaan: "ECH0900097",
  celcoa: "ECH0900096",
  patita: "ECH0900070",
  tarje: "ECH0900071",
  trav01: "ECH0900016",
  sevmen: "ECH0900079",
  sevian: "ECH0900078",
  celem: "ECH0900085",
  containerDyTravel: '8781923',
  domainDy: 'chubb.com'
};
