<footer class="text-center">
  <div class="bg-md-primary content text-center">
    <section class="container section-inside-footer">
      <ul class="contact-list list-unstyled">
        <li class="contact-item">
          <a class="contact-link" href="tel:02-395-7731">
            <mat-icon svgIcon="click_to_call"></mat-icon>
            <span class="contact-text">02-395-7731</span>
          </a>
        </li>
        <li class="contact-item">
          <a class="contact-link" href="mailto:chubbecuador@chubb.com?subject=E-Commerce BG - Llamada agendada">
            <mat-icon svgIcon="envelope"></mat-icon>
            <span class="contact-text">chubbecuador@chubb.com</span>
          </a>
        </li>
      </ul>
      <hr class="divider" />
      <ul class="legal-list list-unstyled">
        <li>
          <a [routerLink]="['/terms-conditions']">Términos y Condiciones</a>
        </li>
        <li>
          <a [routerLink]="['/privacy-policies']">Políticas de Privacidad</a>
        </li>
      </ul>
      <div>
        <figure class="my-4 pt-4 d-flex justify-content-between container-logos">
          <img alt="Logo de Chubb Seguros" src="../assets/svg/logo-chubb-white.svg" />
          <img alt="Logo de Banco Guayaquil" class="bg" src="../assets/img/bg-white-logo.png" />
        </figure>

        <small class="copyright">
          © 2020 Chubb Group. Productos ofrecidos por una o más de las Compañías de Chubb Group para clientes Banco Guayaquil. Los productos ofrecidos no se encuentran disponibles en todas las jurisdicciones. Los derechos sobre la marca comercial "Chubb", su logotipo, y demás marcas relacionadas, son de propiedad de Chubb Limited.
        </small>
      </div>
    </section>
  </div>
</footer>
