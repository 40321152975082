<p class="text-center py-5 mb-5">
  Tenemos expertos en servicio al cliente que te pueden ayudar a resolver tus dudas. Nuestro objetivo es ayudarte y apoyarte en el proceso de tu compra.
</p>
<ul class="contact-list list-unstyled">
  <li class="contact-item">
    <a class="contact-link" href="#" data-cy="help-agendar1" (click)="openScheduleCall($event)">
      <mat-icon svgIcon="call_center"></mat-icon>
      <span class="contact-text">Agenda una llamada</span>
    </a>
  </li>
  <li class="contact-item">
    <a class="contact-link" href="tel:1700-111-999">
      <mat-icon svgIcon="click_to_call"></mat-icon>
      <span class="contact-text">1700-111-999</span>
    </a>
  </li>
  <li class="contact-item">
    <a class="contact-link" href="tel:02-395-7731">
      <mat-icon svgIcon="click_to_call"></mat-icon>
      <span class="contact-text">02-395-7731</span>
    </a>
  </li>
  <li class="contact-item">
    <a class="contact-link" href="mailto:chubbecuador@chubb.com?subject=E-Commerce BG - Llamada agendada">
      <mat-icon svgIcon="envelope"></mat-icon>
      <span class="contact-text">chubbecuador@chubb.com</span>
    </a>
  </li>
  <li class="contact-item">
    <a class="contact-link" href="https://api.whatsapp.com/send?phone=59323420092&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20los%20seguros%20que%20Chubb%20tiene%20para%20clientes%20Banco%20Guayaquil%20😊." target="_blank">
      <mat-icon svgIcon="whatsapp"></mat-icon>
      <span class="contact-text">Chat Whatsapp</span>
    </a>
  </li>
</ul>
