<p class="top">Por la presente autorizo de forma inequívoca a Chubb Seguros Ecuador (“Chubb”) para el tratamiento de mis datos
  personales en virtud de la contratación de seguros, entendido como la recolección, transferencia, almacenamiento,
  análisis y perfilamiento y, tratamiento en general de mis datos personales que sean necesarias para la finalidad
  descrita. Asimismo, autorizo a Chubb a utilizar mi información personal para validación de identidad y/o cualquier
  otra operación necesaria para el servicio prestado por Chubb en virtud de la relación contractual o precontractual que
  nos vincule; por lo que autorizo expresamente a Chubb a contactarme, por si o mediante terceros, para fines
  publicitarios, comerciales o meramente informativos a mi teléfono (fijo y/o celular), correo electrónico y/o domicilio
  personal.</p>
<p>Autorizo a Chubb a transferir mis datos personales a terceros, siempre y cuando se encuentre dentro de las
  finalidades descritas en este consentimiento, como también a realizar la transferencia y/o almacenamiento
  internacional de mis datos personales tanto a Chubb como a cualquier tercero que opere en su nombre, por el tiempo que
  dure la relación contractual o pre- contractual y posterior por causas legitimas de tratamiento establecido en la
  normativa aplicable.</p>
<p>Por último, declaro conocer y tener a disposición la Política de Privacidad de Chubb en <a
    href="https://www.chubb.com/ec-es/" target="_blank">https://www.chubb.com/ec-es/</a>,
  a la vez que declaro conocer mis derechos de acceso, rectificación y revocación sobre mis datos personales, lo cuales
  podrán ser ejercidos mediante el envío de correo electrónico <a href="mailto:privacidadecuador@chubb.com">privacidadecuador@chubb.com</a> o al número telefónico <a href="tel:042
  3731810">2
  3731810</a> Ext 2412 en cualquier momento.</p>
