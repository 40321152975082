import { CampaignService } from "./campaign.service";
import { CookieService } from "ngx-cookie-service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SessionStorageService } from "./session-storage.service";
import { environment as env } from "../../environments/environment";
import { map } from "rxjs/operators";
import { query } from "@angular/animations";

interface DeviceInfoPayload {
  frontend_name: string;
  frontend_version: string;
}

interface BeneficiaryPayload {
  name: string;
  lastname: string;
  surname: string;
  phone: string;
  identificationNumber: string;
  relationship: number;
  percentage: number;
}

interface InsuredPayload {
  product_id: number;
  benefitLevel: number;
  "S6LATAM.PaymentMethod": number;
  "S6LATAM.PaymentFrequency": number;
  name: string;
  lastname: string;
  surname: string;
  phone: string;
  identificationNumber: string;
  birthdate: string;
  ocuppation: string;
  email: string;
}

interface PayPayload {
  method: string;
  type: string;
  number: string;
  insured: InsuredPayload;
  beneficiaries: BeneficiaryPayload[];
  device_info: DeviceInfoPayload;
}

interface MailBox {
  product: string,
  name: string,
  code: string,
  saleChannel: string,
  saleType: string,
  inactive: boolean
}

@Injectable({
  providedIn: "root",
})
export class PayService {
  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    public campaignService: CampaignService,
    private _sessionSt: SessionStorageService
  ) { }

  static readonly PROMO = {
    ZERO: "fee_zero"
  };
  static readonly payCardTypes = {
    credit: "credit",
    debit: "saving",
    creditText: "Tarjeta de crédito",
    debitText: "Cuenta de Ahorros",
  }
  static readonly payCardBrands = {
    americanExpress: "AMEX",
    visa: "VISA",
    mastercard: "MCCR",
    mastercardPrefix: "MASTER",
    amexText: "American Express",
    visaText: "VISA",
    mccrText: "MasterCard",
  }
  static readonly payCardAssets = {
    amex: "amex-min.svg",
    visa: "svgvisa-min.svg",
    mastercard: "mastercard-logo-min.svg",
    default: "money-icon-blue.svg",
  }

  hasFeeZeroPromo() {
    const queryParams = JSON.parse(sessionStorage.getItem("queryParams"));
    return queryParams ? queryParams[PayService.PROMO.ZERO] : false;
  }

  checkPromoParams(data: any) {
    let objectResult: any = null;
    const queryParams = JSON.parse(sessionStorage.getItem("queryParams"));
    const productFeeZero = data.product?.productFeeZero;
    if (productFeeZero) {
      if (queryParams && queryParams[PayService.PROMO.ZERO]) {
        objectResult = {
          ...objectResult,
          feeZero: productFeeZero,
          mailBox: false
        }
      }
    }
    return objectResult;
  }

  sendPayData(data: any) {
    const url = "https://run.mocky.io/v3/b2174250-b647-415d-ad29-6237ed1ffb50";
    const promoObj = this.checkPromoParams(data);
    const frontendVersion = "6.5";
    const GACookie = this.cookieService.get("_ga");
    const GACookieStr = GACookie.substring(GACookie.indexOf(".", 4) + 1);
    const dataWVersion = {
      ...data,
      deviceInfo: {
        ...data.deviceInfo,
        frontend_version: frontendVersion,
      },
      gavalue: GACookieStr,
      ...promoObj
    };

    return this.httpClient.post<any>(`${env.baseUrl}pay`, dataWVersion).pipe(
    //return this.httpClient.post<any>(url, dataWVersion).pipe(
      map((data) => {
        return data.data;
      })
    );
  }

  inactiveMailBox(typeSale: string, code: string, idOffer: string): Observable<MailBox> {
    return this.httpClient.get<MailBox>(`${env.baseUrl}inactiveMailBox/${typeSale}/${code}/${idOffer}`);
  }
}
