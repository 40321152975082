import { ValidatorFn, AsyncValidatorFn } from "@angular/forms";

export class Field<T> {
  value: T;
  id: string;
  classes: string;
  key: string;
  mask: string;
  group: any;
  label: string;
  legend?: string;
  legendHelp?: string;
  required?: boolean;
  readonly?: boolean;
  validations: ValidatorFn[];
  asyncValidations?: AsyncValidatorFn[];
  errorMessages: ValidationMessage[];
  help: string;
  prefixText: string;
  suffixText: string;
  config: any;
  addLines: string[];
  step: number;
  order: number;
  controlType: string;
  validateOn: "change" | "blur" | "submit";
  modalToOpen?: string;
  label2?: string;

  constructor(
    options: {
      value?: T;
      id?: string;
      classes?: string;
      mask?: string;
      key?: string;
      group?: any;
      label?: string;
      legend?: string;
      legendHelp?: string;
      required?: boolean;
      readonly?: boolean;
      validations?: ValidatorFn[];
      asyncValidations?: AsyncValidatorFn[];
      errorMessages?: ValidationMessage[];
      help?: string;
      prefixText?: string;
      suffixText?: string;
      config?: object;
      addLines?: string[];
      step?: number;
      order?: number;
      controlType?: string;
      validateOn?: "change" | "blur" | "submit";
      modalToOpen?: string;
      label2?: string;
    } = {}
  ) {
    this.value = options.value;
    this.classes = options.classes;
    this.mask = options.mask || "";
    this.id = options.id || "";
    this.key = options.key || "";
    this.group = options.group || null;
    this.label = options.label || "";
    this.legend = options.legend || "";
    this.legendHelp = options.legendHelp || "";
    this.required = options.required || false;
    this.readonly = options.readonly || false;
    this.validations = options.validations || [];
    this.asyncValidations = options.asyncValidations || [];
    this.errorMessages = options.errorMessages || [];
    this.help = options.help || "";
    this.prefixText = options.prefixText || "";
    this.suffixText = options.suffixText || "";
    this.config = options.config || null;
    this.addLines = options.addLines || [];
    this.step = options.step === undefined ? 0 : options.step;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || "";
    this.validateOn = options.validateOn || "change";
    this.modalToOpen = options.modalToOpen || "";
    this.label2 = options.label2 || null;
  }
}
export interface ValidationMessage {
  rule: string;
  message: string;
}
