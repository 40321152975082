import { Injectable } from "@angular/core";

@Injectable()
export class TitleModalConfig {
  emphasisTitle: string;
  bgclass?: string;
  additionalTitle?: string;
  icon?: string;
  logo?: boolean;
  data?: any;
  noShowClose?: boolean;

  constructor(
    options: {
      emphasisTitle?: string;
      bgclass?: string;
      additionalTitle?: string;
      icon?: string;
      logo?: boolean;
      data?: any;
      noShowClose?: boolean;
    } = {}
  ) {
    this.emphasisTitle = options.emphasisTitle || "";
    this.bgclass = options.bgclass || "";
    this.additionalTitle = options.additionalTitle || "";
    this.icon = options.icon || "";
    this.logo = options.logo || true;
    this.data = options.data || {};
    this.noShowClose = options.noShowClose || false;
  }
}
