import { CommonModule } from '@angular/common';
import { GatrackButtonDirective } from '@app/directives/gatrack-button.directive';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [GatrackButtonDirective],
  imports: [
    CommonModule
  ],
  exports: [
    GatrackButtonDirective
  ]
})
export class DirectivesModule { }
