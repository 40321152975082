<div>
  <p *ngIf="description" class="text-center form-description">{{ description }}</p>
  <form [attr.data-cy]="isDialog ? 'form-in-dialog' : 'form'" class="form-comp" (ngSubmit)="onSubmit($event)"
    [formGroup]="form" [name]="formName" [ngClass]="{'has-description': description}" #formchild>
    <fieldset [disabled]="disabledForm" class="container fieldset-comp" [ngClass]="{
        'is-dialog': isDialog,
        'no-inner-steps': !currentInnerStep
      }" #fieldset>
      <div *ngFor="let field of fields" class="form-row" [class]="field.classes" [ngClass]="{
          'w-100p': field.controlType == 'checkbox' || field.config?.hint || field.config?.textCTA || field.legend,
          'previous-step-field': currentInnerStep && field.step < currentInnerStep,
          'current-step-field': currentInnerStep && field.step == currentInnerStep
        }">
        <app-field [field]="field" [form]="form" (customEvent)="triggerCustomEvent($event)"
          (updateEvent)="updateValue($event)" (blurEvent)="onBlur($event)" (updateRangeEvent)="updateValueGroup($event)"
          class="field-container">
        </app-field>
      </div>
    </fieldset>
    <ng-content select=".child-container"></ng-content>
    <div class="form-row justify-content-around submit-container"
      [ngClass]="{'d-none': noShowSubmit, 'mt-4': isDialog}">
      <button mat-flat-button data-cy="submit-validate" type="submit" color="accent"
        [disabled]="!(form.valid && (consentOfUsesValid !== null ? consentOfUsesValid : true))" [appGatrackButton]="submitText">{{ submitText }}
        <mat-icon *ngIf="submitIcon">{{submitIcon}}</mat-icon></button>
      <!--button *ngIf="isDialog" mat-stroked-button type="button" (click)="onCancel()">Cancel</button-->
    </div>
  </form>
</div>