<div [formGroup]="form">
  <div [ngSwitch]="field.controlType" [ngClass]="{
      'd-lg-flex': field.config?.hint || field.config?.textCTA || field.legend,
      'full-legend': field.legend && field.step
    }">
    <legend class="px-2 legend-title d-lg-flex align-items-center" *ngIf="field.legend">
      {{ field.legend }}
      <mat-icon *ngIf="field.legendHelp" [matTooltip]="field.legendHelp"
        matTooltipPosition="above">info_outlined</mat-icon>
    </legend>
    <mat-form-field [formGroup]="form" *ngSwitchCase="'textbox'" appearance="outline">
      <mat-label>{{ field.label }}</mat-label>
      <span class="text-prewrap" matPrefix *ngIf="field.prefixText">{{
        field.prefixText
        }}</span>
      <input #input *ngIf="!field.mask" matInput [attr.data-cy]="'field-' + field.id" [name]="field.key"
        (blur)="onBlur($event)" (keyup)="onKeypress($event)" [id]="field.id" [type]="field.type"
        [formControlName]="field.key" [required]="field.required" [readonly]="field.readonly"
        [maxlength]="field.config?.maxLength" />
      <input *ngIf="field.mask && field.mask == 'currency'" currencyMask matInput [name]="field.key"
        (blur)="onBlur($event)" [id]="field.id" [type]="field.type" [formControlName]="field.key"
        [required]="field.required" [readonly]="field.readonly" />
      <mat-icon *ngIf="field.help" matSuffix [matTooltip]="field.help" matTooltipPosition="above" #tooltip="matTooltip"
        (click)="tooltip.toggle()">info_outlined</mat-icon>
      <span matSuffix *ngIf="field.suffixText">{{ field.suffixText }}</span>
      <mat-error *ngIf="hasError">{{ errorMessage }}</mat-error>
    </mat-form-field>

    <app-field-choices (updateChoice)="updateValue($event)" [formGroup]="form" *ngSwitchCase="'choice'"
      [key]="field.key" [value]="field.value" [hasLogo]="field.config?.hasLogo"
      [logoDefault]="field.config?.logoDefault" [configAutocomplete]="field.config?.configAutocomplete"
      [checkbox]="field.config?.checkbox" [choices]="field.options">
    </app-field-choices>

    <app-field-chips-complete (updateChoices)="updateValue($event)" *ngSwitchCase="'chips-autocomplete'" [id]="field.id"
      [key]="field.key" [value]="field.value" [allOptions]="field.options">
    </app-field-chips-complete>

    <mat-form-field [formGroup]="form" *ngSwitchCase="'couponcode'" appearance="outline" class="w-100 flex-lg-fill">
      <mat-label>{{ field.label }}</mat-label>
      <input matInput autocomplete="one-time-code" [attr.data-cy]="'field-' + field.id"
        [maxlength]="field.config?.maxLength" [id]="field.id" [type]="field.type" [formControlName]="field.key"
        [required]="field.required" [readonly]="field.readonly" />
      <mat-icon *ngIf="field.help" matSuffix [matTooltip]="field.help"
        matTooltipPosition="above">info_outlined</mat-icon>
      <mat-error *ngIf="hasError">{{ errorMessage }}</mat-error>
    </mat-form-field>

    <a href="#" [attr.data-cy]="'cta-' + field.id"
      class="d-block d-lg-inline-flex align-items-center text-nowrap mb-3 text-center cta-button d-block mx-auto text-underline"
      color="accent" *ngIf="
        field.controlType == 'couponcode' &&
        field.config &&
        field.config.textCTA
      " (click)="onClickNoCode($event)">
      <!--button type="button"-->
      {{ field.config.textCTA }}
    </a>

    <a href="#" class="d-block my-3 text-center cta-button d-block text-underline" color="accent"
      *ngIf="field.config && field.config.type == 'CTA'" (click)="openModalCTA($event, field.config)">
      <!--button type="button"-->
      {{ field.config.text }}
    </a>

    <small
      class="d-block d-lg-inline-flex align-items-center text-nowrap mb-3 text-center d-block mx-auto text-underline"
      color="accent" *ngIf="field.config && field.config.helpText">
      <!--button type="button"-->
      {{ field.config.helpText }}
    </small>

    <mat-form-field [formGroup]="form" *ngSwitchCase="'dropdown'" appearance="outline">
      <mat-label>{{ field.label }}</mat-label>
      <mat-select [formControlName]="field.key" [value]="field.value" [attr.data-cy]="'field-' + field.id">
        <mat-option *ngFor="let opt of field.options" [value]="opt.key">
          {{ opt.value }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="hasError">{{ errorMessage }}</mat-error>
    </mat-form-field>

    <mat-form-field [formGroup]="form" *ngSwitchCase="'textarea'" appearance="outline">
      <mat-label>{{ field.label }}</mat-label>
      <textarea matInput [attr.data-cy]="'field-' + field.id" [id]="field.id" [formControlName]="field.key"
        [value]="field.value" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5" [required]="field.required"></textarea>
      <mat-error *ngIf="hasError">{{ errorMessage }}</mat-error>
    </mat-form-field>

    <mat-form-field [formGroup]="form" *ngSwitchCase="'date'" appearance="outline">
      <mat-label>{{ field.label }}</mat-label>
      <input matInput [attr.data-cy]="'field-' + field.id" [readonly]="field.readonly" [matDatepicker]="datepicker"
        [id]="field.id" [formControlName]="field.key" [min]="field.config.minDate" [max]="field.config.maxDate"
        [matDatepickerFilter]="field.config?.filter" />
      <mat-datepicker-toggle matSuffix [for]="datepicker" [attr.data-cy]="'field-toggle-' + field.id"
        [disabled]="field.readonly"></mat-datepicker-toggle>
      <mat-datepicker [touchUi]="field.config?.touch" (click)="datepicker.open()" (keydown)="(false)" #datepicker
        [attr.data-cy]="'field-picker-' + field.id"></mat-datepicker>
      <mat-error *ngIf="hasError">{{ errorMessage }}</mat-error>
    </mat-form-field>

    <app-field-daterangepicker *ngSwitchCase="'daterange'" class="w-100" [label]="field.label" [id]="field.id"
      [key]="field.key" [value]="field.value" (updateRangeEvent)="updateValue($event)">
    </app-field-daterangepicker>

    <mat-form-field [formGroup]="form" *ngSwitchCase="'time'" appearance="outline">
      <mat-label>{{ field.label }}</mat-label>
      <input matInput [attr.data-cy]="'field-' + field.id" [ngxTimepicker]="timepicker" [id]="field.id"
        [formControlName]="field.key" [min]="field.config.minTime" [max]="field.config.maxTime" />
      <ngx-material-timepicker #timepicker></ngx-material-timepicker>
    </mat-form-field>
    <!--mat-form-field *ngSwitchCase="'time'" appearance="outline">
      <mat-label>{{field.label}}</mat-label>
      <app-input-time></app-input-time>
      <mat-icon matSuffix>phone</mat-icon>
      <mat-hint>Include area code</mat-hint>
    </mat-form-field-->
    <div *ngSwitchCase="'time'" class="d-flex align-items-center mb-4 time-hint">
      <mat-icon class="mr-2">access_time</mat-icon> {{ field.config.hint }}
    </div>
    <!--div class="time-picker-component">
      <mat-form-field class="w-100 time-picker-field" appearance="outline">
        <mat-label>{{ field.label }}</mat-label>
        <input matInput type="time" placeholder="HH:MM" [formControlName]="field.key">
        <mat-select name="ampm" class="ampm">
              <mat-option value="AM">AM</mat-option>
              <mat-option value="PM">PM</mat-option>
        </mat-select>
      </mat-form-field>
    </div-->

    <label [formGroup]="form" *ngSwitchCase="'radio'" class="example-radio-label">{{ field.label }}</label>
    <mat-radio-group *ngSwitchCase="'radio'" [attr.data-cy]="'field-' + field.id" [id]="field.id"
      [formControlName]="field.key" class="example-radio-group">
      <mat-radio-button class="example-radio-button" *ngFor="let opt of field.options" [value]="opt.key">
        {{ opt.value }}
      </mat-radio-button>
    </mat-radio-group>

    <mat-checkbox [attr.data-cy]="'field-' + field.id" [formGroup]="form" [id]="field.id" *ngSwitchCase="'checkbox'"
      [formControlName]="field.key" class="checkbox-container">
      <span>{{ field.label }}</span>
      <a href="#" data-cy="detail-autorize" (click)="openModalCheckbox($event, field?.modalToOpen)">{{ field.help }}</a>
      <span *ngIf="field?.label2">{{ field?.label2 }}</span>
      <br />
      <span *ngFor="let line of field.addLines"> {{ line }} <br /> </span>
    </mat-checkbox>

    <app-field-helpertooltip *ngSwitchCase="'helpertooltip'" [helperTooltip]="field.config">
    </app-field-helpertooltip>
  </div>

  <!--div class="errorMessage" *ngIf="!isValid">{{field.label}} is required</div-->
</div>
