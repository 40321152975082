<header id="header">
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light fixed-top bg-white box-shadow"
    style="z-index: 2">
    <section class="container">
      <a class="navbar-brand" [routerLink]="['/home']" [queryParams]="useOperation ? {operation_id: opId}: {}"
        data-cy="header-home-link">
        <img alt="Logo de Chubb Seguros" src="../assets/svg/logo-chubb-blue.svg" width="138" height="14" />
      </a>
      <div class="icons">
        <button mat-icon-button aria-label="Click to Call" (click)="openDialog()" [disableRipple]="true"
          data-cy="header-ctc">
          <mat-icon svgIcon="phone"></mat-icon>
        </button>
      </div>
    </section>
  </nav>
</header>
