import { Component, OnInit } from "@angular/core";

import { CampaignService } from "./../../../services/campaign.service";
import { Events } from "@app/enums/events.enum";
import { ModalLegacyService } from "@app/services/modal-legacy.service";
import { ProductPageRouteService } from "@app/services/product-page-route.service";

@Component({
  selector: "app-public-header",
  templateUrl: "./public-header.component.html",
  styleUrls: ["./public-header.component.scss"],
})
export class PublicHeaderComponent implements OnInit {
  saleStatus;
  opId: string;
  submitSrvFn;
  currentRoute: string;
  useOperation: boolean = false;
  layout: string = 'legacy';
  pageData: any = null;

  constructor(
    private campaignService: CampaignService,
    public productPageRouteService: ProductPageRouteService,
    private modalLegacyService: ModalLegacyService
  ) {
    this.campaignService.successSaleFlag$.subscribe((msg) => {
      this.saleStatus = this.campaignService.getSaleStatus();
      this.opId = this.saleStatus.operationId || sessionStorage.operation_id;
      const saleType = this.saleStatus.type;
      const criteriaSale = this.opId && !["VL", "VS"].includes(saleType);
      if (msg && criteriaSale) {
        this.useOperation = true;
      } else {
        this.useOperation = false;
      }
    });
    this.productPageRouteService.typeLayout$.subscribe((msg) => {
      if (msg === 'legacy') {
        this.pageData = null;
      } else if (msg === 'lib') {
        this.pageData = this.productPageRouteService.getData();
      }
      this.layout = msg;
    });
  }

  ngOnInit(): void {
    this.saleStatus = this.campaignService.getSaleStatus();
  }

  openDialog(): void {
    this.modalLegacyService.sendModalClickToCall(Events.ClickToCallEvent);
  }
}
