<div class="options-group">
  <input type="hidden" [value]="value" [name]="key" />
  <ng-container *ngFor="let choice of choices; index as i">
    <ng-container *ngIf="!isObject(choice)">
      <ng-container *ngIf="!checkbox">
        <button type="button" mat-stroked-button color="primary" [ngClass]="{'has-logo': hasLogo, 'active': value == choice}" (click)="selectOption(choice)" [value]="choice">
          <img alt="" *ngIf="hasLogo" src='../assets/img/logos/{{value == choice ? choice+".active" : choice}}.svg' (error)="onImgError($event, logoDefault)" />
          {{choice | titlecase}}
        </button>
      </ng-container>
      <ng-container class="options-check" *ngIf="checkbox">
        <input  type="checkbox" id="{{choice}}" name="{{choice}}" value="choice"  (click)="selectCheck(choice)" >
        <label  for="{{choice}}">{{choice}}</label><br>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isObject(choice)">
      <button type="button" mat-stroked-button color="primary" [ngClass]="{'active': value == choice}" (click)="selectOption(choice)" [value]="choice.key">{{choice.value}}</button>
    </ng-container>
  </ng-container>
  <div class="w-100 field-container-autocomplete" *ngIf="configAutocomplete">
    <div class="mat-button mat-stroked-button mat-primary autocomplete-container" color="primary" (click)="toggleAutocomplete($event)">
      <span>{{configAutocomplete.text}}</span>
      <mat-form-field [formGroup]="formGroup" appearance="outline" [ngClass]="{'d-none' : configAutocomplete?.closed}">
        <mat-label>{{configAutocomplete.label}}</mat-label>
        <input #autocompleteInput matInput [attr.list]="'list-'+configAutocomplete.id" autocomplete="off" [attr.data-cy]="'field-'+configAutocomplete.id" [name]="'autocomplete-'+configAutocomplete.key" [matAutocomplete]="auto"
        [formControl]="controlAutocomplete" [id]="'autocomplete-'+configAutocomplete.id" (change)="checkTypeAutocomplete($event)" (keyup)="checkTypeAutocomplete($event)" (blur)="checkTypeAutocomplete($event)" />
        <!--datalist [id]="'list-'+configAutocomplete.id">
          <option *ngFor="let option of optionsAutocomplete" [value]="option"></option>
        </datalist-->
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)='selectOption($event.option.value, false)'>
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="controlAutocomplete.invalid">Ingrese una coincidencia</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
