import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { environment as env } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CivilRegistrationService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  async validateIdentifications(listIds: any, offerId: string): Promise<any> {
    return await lastValueFrom(this.httpClient.post<any>(`${env.baseUrl}civilRegistration/validateIds`, { listIds, offerId }).pipe(map((response: any) => response.data)));
  }
}
