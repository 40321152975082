import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { Observable, Subscriber, of, timer } from "rxjs";
import { delay, map, switchMap } from "rxjs/operators";

import { HttpClient } from "@angular/common/http";
import { OtpService } from "../services/otp.service";
import { PortalService } from "@app/modules/home/services/portal.service";
import { QuoteMobileService } from "../services/quote-mobile.service";
import { environment as env } from "@environments/environment";

export function ageRangeValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (
      control.value !== undefined &&
      (isNaN(control.value) || control.value < min || control.value > max)
    ) {
      return { ageRange: true };
    }
    return null;
  };
}

export function occupationValidator(inputAllowed: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!inputAllowed.includes(control.value)) {
      return { occupation: true };
    }
    return null;
  };
}

export function otpValidator(
  otpService: OtpService,
  time: number = 500
): AsyncValidatorFn {
  return (
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return timer(time).pipe(
      switchMap(() => otpService.getIsValidOTP()),
      map((res) => {
        return !res.isValidOTP ? { otp: true } : null;
      })
    );
  };
}

export function productSearchValidator(
  portalService: PortalService
): AsyncValidatorFn {
  return (control: AbstractControl) => {
    if (control.pristine) {
      return of(null);
    }
    return portalService.getProductsFromSearch(control.value).pipe(
      map((data: any) => {
        portalService.setProductFromSearchData(data);
        return data.length > 0 ? null : { productSearch: true };
      })
    );
  };
}

export function valueInListValidator(list: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!list.includes(control.value.toLowerCase().trim())) {
      return { valueInList: true };
    }
    return null;
  };
}

export function emailCustomValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let email = control.value;
    if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      return {
        emailCustom: true,
      };
    }
    return null;
  };
}
