<div class="py-4">
  <p>Confiero en forma expresa y voluntaria las siguientes autorizaciones, declarando encontrarme debidamente informado
    de su contenido, alcance y efectos:</p>
  <ul class="list-text">
    <li>Autorizo a Banco Guayaquil a transferir a Chubb Seguros Ecuador mi información personal que reposa en sus bases
      de datos, así como también la información que se haya generado en el transcurso de mi relación comercial con el
      Banco. Esta autorización incluye a la información relacionada con los medios de pago que tengo contratados con
      Banco Guayaquil, y particularmente los que utilizo para realizar el pago de los seguros que contraté.</li>
    <li>Autorizo a Chubb Seguros Ecuador a recopilar y almacenar mi información personal que se encuentre en bases de
      datos públicas o privadas, incluidas aquellas que fueren propiedad o estuvieran administradas por Banco Guayaquil.
      Chubb Seguros queda facultada para que conservar dicha información en su propia base de datos y/o para utilizarla
      en la elaboración de planes de seguros orientados a mi comportamiento y preferencias. Autorizo también a Chubb
      Seguros Ecuador para contactarme a fin de comercializar sus productos de seguros o incluirla en procesos de
      contratación de los seguros ofrecidos.</li>
    <li>Declaro conocer que los derechos que me asisten y demás condiciones para el tratamiento de mis datos personales
      se encuentran recogidos en la política de manejo de datos de Chubb Seguros Ecuador, la cual está disponible para
      mi
      revisión en nuestro sitio web. Así también, estoy debidamente informado que puedo consultar en cualquier tiempo la
      política de tratamiento de datos de Banco Guayaquil S. A., en su sitio web institucional.</li>
  </ul>
</div>
