import { APP_DATE_FORMATS, AppDateAdapter } from "@shared/date.adapter";
import { CommonModule, DatePipe, NgOptimizedImage, registerLocaleData } from "@angular/common";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { DynamicYieldSessionStorageService, DynamicYieldTaggingService } from "./services";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule
} from "@angular/common/http";

import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routing";
import { BaseComponent } from './base/base.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CRUX_WINDOW_PROVIDERS } from "./components/providers/window.provider";
import { CookieService } from "ngx-cookie-service";
import { DirectivesModule } from "./modules/directives/directives.module";
import { GatrackButtonDirective } from "./directives/gatrack-button.directive";
import { GoogleTaskManagerDataLayerProvider } from "./components/providers/google-task-manager.provider";
import { GtmTaggingService } from "./services/gtm-tagging.service";
import { LoaderInterceptor } from "@shared/loader.interceptor";
import { LoaderModule } from "shared/loader.module";
import { LoaderService } from "@services/loader.service";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { NgModule } from "@angular/core";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { PublicFooterComponent } from "./Layout/public-layout/public-footer/public-footer.component";
import { PublicHeaderComponent } from "./Layout/public-layout/public-header/public-header.component";
import { PublicLayoutComponent } from "./Layout/public-layout/public-layout.component";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { WINDOW_PROVIDERS } from "./components/providers/window.service";
import es from '@angular/common/locales/es';
import { ModalModule } from "./components/modal/modal.module";

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    PublicHeaderComponent,
    PublicFooterComponent,
    NotFoundComponent,
    BaseComponent,
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    LoaderModule,
    ModalModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatMenuModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DirectivesModule,
    RouterModule.forRoot(AppRoutes, {
      enableTracing: false,
    }),
  ],
  providers: [
    DatePipe,
    GoogleTaskManagerDataLayerProvider,
    WINDOW_PROVIDERS,
    CRUX_WINDOW_PROVIDERS,
    CookieService,
    LoaderService,
    GtmTaggingService,
    DynamicYieldSessionStorageService,
    DynamicYieldTaggingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },

    { provide: Window, useValue: window },
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
