import { lastValueFrom, map } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from "../../environments/environment";

export interface Province {
  bgCode: string;
  bgName: string;
  s6Code: string;
  s6Name: string;
}

@Injectable({
  providedIn: "root",
})
export class ProvinceService {
  data: Province[];
  constructor(
    private httpClient: HttpClient,
  ) { }

  getData() {
    return this.data;
  }

  getProvinceById(id: any) {
    return this.data.find(d => d.bgCode == id);
  }

  async getAllProvinces(): Promise<Province[]> {
    return await lastValueFrom(this.httpClient.get<Province[]>(`${env.baseUrl}province/allProvinces`).pipe(map((response: any) => {
      this.data = response.data.map((e:Province) => { return {...e, eTag: ""} });
      return this.data;
    } )));
  }
}
