import { Directive, HostListener, Input } from '@angular/core';

import { GoogleAnalyticServiceService } from '@app/helpers/google-analytic-service.service';

@Directive({
  selector: '[appGatrackButton]'
})
export class GatrackButtonDirective {
  @Input("appGatrackButton") label: string;
  constructor(
    private googleAnalyticService: GoogleAnalyticServiceService,
  ) { }
  @HostListener("click", ["$event"]) onClick($event) {
    this.trackGA(this.label);
  }

  trackGA(label: string) {
    this.googleAnalyticService.googleTaskManagerPush({
      event: "user_flow",
      category: "clicks_buttons",
      action: "click_call",
      label
    });
  }
}
