import { Injectable, Optional } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment as env } from "../../environments/environment";
import { map } from "rxjs/operators";

export type PayMethodQuery = {
  OnlyType: 'account'|'card';
}
interface PaymentMethod {
  brand: string;
  method: string;
  number: string;
  type: string;
}
interface ICustomerInfoData {
  data: {
    name: string;
    email: string;
    address: {
      province_catalog: number;
      province: string;
      city_catalog: number;
      city: string;
      street: string;
      street_complement: string;
    };
    phone_number: string;
    mobile_number: string;
    gender: string;
    gender_catalog: number;
    identification_type_catalog: number;
    identification: string;
    identification_type: string;
    birthdate: string;
    marital_status: string;
    marital_status_catalog: number;
    economic_activity: string;
    economic_activity_catalog: number;
    nationality_catalog: number;
    nationality: string;
  };
  payment_methods: PaymentMethod[];
}

@Injectable({
  providedIn: "root",
})
export class CustomerInfoService {
  private dataChangedId: any;
  private data: any;
  private dataSpouse: any = null;
  private dataModified: any;
  private dataDependents: any;
  private dataPayer: any;
  private methodsList: any[];
  public rawMethodSelected: any;

  constructor(private httpClient: HttpClient) {}

  public cleanData(res: any): any {
    const dataObj = res.data || res.clientData;
    const trimDataValues = this.trimValues({ ...dataObj });
    this.setData({
      ...trimDataValues,
      payment_methods: res?.payment_methods || this.data?.payment_methods,
    });
    return this.getData();
  }
  public trimValues(data) {
    return Object.entries(data)
      .map((el) => {
        return [el[0], typeof el[1] === "string" ? el[1].trim() : el[1]];
      })
      .reduce((ac, cv: [string, any]) => {
        const obj = ac;
        const keyName: string = cv[0];
        obj[keyName] = cv[1];
        return obj;
      }, {});
  }
  public getDataChangedId() {
    return this.dataChangedId;
  }
  public getData() {
    return this.data;
  }
  public setSpouseData(data) {
    this.dataSpouse = data;
  }
  public getSpouseData() {
    return this.dataSpouse;
  }
  public setDataChangedId(data) {
    this.dataChangedId = {
      ...this.dataChangedId,
      ...data,
      gender: data?.gender || this.dataChangedId?.gender
    };
  }
  public setData(data) {
    this.data = data;
  }
  public getDataModified() {
    if (this.dataModified == null) {
      this.dataModified = this.data;
    }
    return this.dataModified;
  }
  public setDataModified(data) {
    this.dataModified = {
      ...this.dataModified,
      ...data,
    };
  }
  public setRawMethodSelected(data) {
    this.rawMethodSelected = data;
  }
  public getDataDependents() {
    return this.dataDependents;
  }
  public setDataDependents(data) {
    let dataFiltered = data.filter(d => Object.keys(d).length);
    this.dataDependents = dataFiltered;
  }

  public getDataPayer() {
    return this.dataPayer;
  }
  public setDataPayer(data) {
    this.dataPayer = data;
  }

  public resetData() {
    this.data = null;
    this.dataModified = null;
  }

  public getCustomerInfoData(data) {
   const url = "https://run.mocky.io/v3/0c75a575-518b-4be0-926c-b180881d3653";//"https://run.mocky.io/v3/26a6b12b-eac6-477e-a140-16477899fa6c";
    return this.httpClient
    .post<any>(`${env.baseUrl}client`, data)
    //.post<any>(url, data)
      .pipe(map((data) => data));
  }

  public getMethodsList() {
    return this.methodsList;
  }

  getPaymentMethod(idOffer: string, mobile_number: string, query: PayMethodQuery = null): Observable<any> {
    const url = "https://run.mocky.io/v3/c37b6b1f-4544-436e-aa99-1fae000c3cda";
    return this.httpClient.post<any>(
    `${env.baseUrl}payMethods/${idOffer}/${mobile_number}`, query || {}
    //url
    );
  }
  public checkSession(idOffer: string) {
    return this.httpClient
      .post<any>(`${env.baseUrl}check-session`, {
        offerId: idOffer,
      })
      .pipe(map((data) => data));
  }
}
