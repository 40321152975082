<ul>
  <li>
    <button [disableRipple]="true" mat-button [matMenuTriggerFor]="carga">Carga Archivos</button>
    <mat-menu #carga="matMenu">
      <button mat-menu-item routerLink="/admin/cargar-listas-negocio">Cargar Listas Negocio</button>
      <button mat-menu-item routerLink="/admin/cargar-tareas-whatsapp">Whatsapp</button>
      <button mat-menu-item routerLink="/admin/cargar-catalogos">Catalogos</button>
    </mat-menu>
  </li>
</ul>
<span class="spacer"></span>