import { CampaignService } from "./campaign.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from "../../environments/environment";
import { map } from "rxjs/operators";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class QuoteTravelService {
  private data: any;
  public campaignData = this.campaignService.getData();
  private cleanData(res) {
    this.setData({ ...res.data });
    return this.getData();
  }
  public getData() {
    return this.data;
  }
  public setData(data) {
    this.data = data;
  }

  constructor(
    private httpClient: HttpClient,
    private campaignService: CampaignService
  ) {}

  getCustomPayload(payload) {
    const destinyType = {
      Nacional: "National",
      Internacional: "International",
    };
    const travelType = {
      ["Solo Ida"]: "One Way",
      ["Ida y Regreso"]: "Round Trip",
    };
    const qtyTravelers = payload["qty-travelers"];
    const countries = this.campaignService.getCatalogsData("countries");
    const cities = this.campaignService.getCatalogsData("cities");
    let countriesDestinations = [];
    let agesTravelers = [];
    if (payload.destinyType == "Internacional") {
      countriesDestinations = countries.filter(
        (d) => d.Name_ES == payload.destinations.find((pd) => pd == d.Name_ES)
      );
    } else {
      countriesDestinations = countries.filter((d) => d.name == "Ecuador");
    }
    if (qtyTravelers >= 1) {
      agesTravelers.push(payload["own-age"]);
    }
    if (qtyTravelers >= 2) {
      Array.from({ length: payload["qty-travelers"] - 1 }).forEach((a, i) => {
        agesTravelers.push(payload[`age-${i + 1}`]);
      });
    }
    const validAges = agesTravelers.find((av) => av >= 80);
    return {
      Destination_Type: destinyType[payload.destinyType],
      City:
        payload.destinyType == "Nacional"
          ? cities.find((c) => payload.city == c.CityName)
          : null,
      Trip_Type: travelType[payload.travelType],
      DestinationArray: countriesDestinations,
      Date_Departure: payload.startDate,
      Date_Return:
        payload.travelType == "Ida y Regreso"
          ? payload.backDate
          : payload.startDate,
      Number_Travelers: qtyTravelers,
      Travelers_Age: agesTravelers.toString(),
      Ticket_Cost: payload.cost,
      OfferId: this.campaignData.id_offer,
      type: this.campaignService.getSaleStatus().type,
      DataCanalSale: this.campaignService.getData(),
    };
  }

  validateForm(data: any) {
    let dataValidate = { ...data };
    let validAges = [];
    let invalidAgesFields = [];
    let result = {
      invalid: false,
    };
    if (dataValidate["qty-travelers"] && dataValidate["qty-travelers"] > 1) {
      Array.from({ length: dataValidate["qty-travelers"] - 1 }).forEach(
        (a, i) => {
          if (dataValidate[`age-${i + 1}`] <= 80) {
            validAges.push(dataValidate[`age-${i + 1}`]);
          } else if (dataValidate[`age-${i + 1}`] > 80) {
            invalidAgesFields.push(`age-${i + 1}`);
            dataValidate[`age-${i + 1}`] = "";
            result.invalid = true;
          }
        }
      );
      dataValidate["qty-travelers"] = validAges.length + 1;
    }
    if (dataValidate["own-age"] > 80) {
      dataValidate["own-age"] = "";
      result.invalid = true;
    }

    if (invalidAgesFields.length) {
      if (dataValidate["qty-travelers"] > 1) {
        invalidAgesFields.forEach((a, i) => {
          dataValidate[`age-${i + 1}`] = validAges[i];
        });
      }
    }
    return {
      ...result,
      payload: dataValidate,
      modifiedPayload: data,
    };
  }

  getRemoteData(data: any) {
    return this.httpClient
      .post<any>(`${env.baseUrl}travel/quotetravel`, data)
      .pipe(map((data) => this.cleanData(data)));
  }

  quoteTravel(data: any) {
    return this.httpClient
      .post(`${env.baseUrl}travel/quote`, data)
      .pipe(map((data) => data));
  }
}
