import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-content-is-customer',
  templateUrl: './modal-content-is-customer.component.html',
  styleUrls: ['./modal-content-is-customer.component.scss']
})
export class ModalContentIsCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
