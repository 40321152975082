<header>
    <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light fixed-top bg-white border-bottom box-shadow" style="z-index: 2">
      <section class="container">
        <a class="navbar-brand" href="/home" data-cy="header-home-link">
          <img alt="Logo de Chubb Seguros" src="../assets/svg/logo-chubb-blue.svg" />
        </a>
        <div class="icons">

          <mat-toolbar class="main-menu">
            <div fxShow="true" fxHide.lt-md="true" fxFlex="<flex-basic>">
              <app-secure-menu></app-secure-menu>
            </div>
          </mat-toolbar>
        </div>
      </section>
    </nav>
  </header>
