<mat-form-field class="example-chip-list" appearance="outline">
  <mat-chip-list #chipList aria-label="Lista de Selección">
    <mat-chip
      *ngFor="let option of options"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(option)">
      {{option}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="Destino(s)"
      #childInput
      [formControl]="inputCtrl"
      [attr.data-cy]="'field-'+id"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let op of filteredOptions | async" [value]="op">
      {{op}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
