import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl, UntypedFormGroup } from "@angular/forms";

import { AuthorizationInfoComponent } from "../authorization-info/authorization-info.component";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { Field } from "@shared/field.model";
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from "../modal/modal.component";
import { ModalConsentOfUsesComponent } from "../modal-consent-of-uses/modal-consent-of-uses.component";
import { ResponseTitleComponent } from "../response-title/response-title.component";
import { TitleModalConfig } from "@shared/title-modal-config";
import { take } from "rxjs/operators";

@Component({
  selector: "app-field",
  templateUrl: "./field.component.html",
  styleUrls: ["./field.component.scss"]
})
export class FieldComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() field: Field<any> | any;
  @Input() form: UntypedFormGroup;
  @Output() customEvent = new EventEmitter<any>();
  @Output() updateFieldEvent = new EventEmitter<any>();
  @Output() updateEvent = new EventEmitter<any>();
  @Output() blurEvent = new EventEmitter<any>();
  @ViewChild("input") el: ElementRef;
  @ViewChild("autosize") autosize: CdkTextareaAutosize;

  triggerResize() {
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }
  
  constructor(public dialog: MatDialog, private _ngZone: NgZone) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {}

  ngAfterViewInit(): void {
    if (this.field.config?.autofocus) {
      this.el.nativeElement?.focus();
      this.el.nativeElement?.blur();
      this.el.nativeElement?.focus();
    }
  }
  get isValid() {
    return this.form.controls[this.field.key].valid;
  }

  get hasError() {
    return !this.form.controls[this.field.key].valid;
  }

  get errorMessage() {
    const hasErrors = this.form.controls[this.field.key].errors;
    if (!hasErrors) return;
    const errorRule = Object.keys(hasErrors)[0];
    const errorMessage = this.field.errorMessages.find(
      (e) => e.rule === errorRule
    );
    if (!errorMessage) return;
    return errorMessage.message;
  }

  onClickNoCode($event) {
    $event.preventDefault();
    this.customEvent.emit({ event: $event, message: "No Code" });
  }

  openModalCTA($event, config) {
    $event.preventDefault();
    this.openDialog(config);
  }
  onKeypress($event) {
    if (this.field.config?.keyPressEvt && this.form.status == "INVALID") {
      this.form.controls[this.field.key].setErrors(null);
    }
  }

  openDialog(config) {
    const dialogRef = this.dialog.open(ModalComponent, {
      id: "dialog-from-field",
      backdropClass: "backdrop-blur",
      panelClass: "panel-mobile",

      minHeight: "80vh",
      maxHeight: "100vh",
      data: {
        ...config.modal,
      },
    });
  }

  openModalCheckbox($event, modalToOpen?: string) {
    $event.preventDefault();
    if (modalToOpen === "consent-of-uses") {
      this.dialog.open(ModalComponent, {
        id: "dialog-consent-of-uses",
        backdropClass: "backdrop-blur",
        panelClass: "panel-mobile",

        minHeight: "80vh",
        maxHeight: "100vh",
        data: {
          title: "Tratamiento de Uso de Datos",
          component: ModalConsentOfUsesComponent,
          confirmActions: {
            confirmLabel: "Entiendo",
          },
        },
      });
    } else {
      var configTitle: TitleModalConfig = {
        logo: true,
        emphasisTitle: "Autorización de Información",
      };
      const dialogRef = this.dialog.open(ModalComponent, {
        id: "dialog-authorization",
        backdropClass: "backdrop-blur",
        panelClass: "panel-mobile",

        minHeight: "80vh",
        maxHeight: "100vh",
        data: {
          titleComponent: ResponseTitleComponent,
          configTitle,
          component: AuthorizationInfoComponent,
          confirmActions: {
            confirmLabel: "Entiendo",
          },
        },
      });
    }
  }
  updateValue($event) {
    this.updateEvent.emit($event);
  }

  onBlur($event) {
    this.blurEvent.emit($event);
  }
}
