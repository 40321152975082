<p class="subject">
  Lo sentimos pero al momento no podemos activar nuestro descuento.
  Por favor intenta con las siguientes opciones:
</p>
<p class="question">
  ¿Eres cliente de Banco Guayaquil?
</p>
<div class="options-group">
  <button mat-stroked-button color="primary" [ngClass]="{'active': isCustomer }" (click)="isCustomerEvent($event, true)">Si soy Cliente</button>
  <button mat-stroked-button color="primary" [ngClass]="{'active': isCustomer === false}" (click)="isCustomerEvent($event, false)">No soy Cliente</button>
</div>
