import { Injectable, Renderer2 } from "@angular/core";

import { Subject } from "rxjs";

@Injectable()
export class LoaderService {
  isLoading = new Subject<boolean>();
  isLoadingNoInterceptor = new Subject<boolean>();
  callUsStatus = new Subject<boolean>();
  showModular: boolean = false;
  message =
    "Estamos procesando tu información, en unos minutos ya podrás contar con los beneficios de tu cobertura de seguro.";
  constructor() {}

  show() {
    this.isLoading.next(true);
  }
  hide() {
    this.isLoading.next(false);
  }
  showCallUs() {
    this.callUsStatus.next(true);
  }
  hideCallUs() {
    this.callUsStatus.next(false);
  }
  showNoInterceptor() {
    this.isLoadingNoInterceptor.next(true);
  }
  hideNoInterceptor() {
    this.isLoadingNoInterceptor.next(false);
  }
  toggleNoInterceptor(show) {
    this.isLoadingNoInterceptor.next(show);
  }
}
