import { Injectable } from "@angular/core";

interface Styles {
  name: string;
  href: string;
}

@Injectable({
  providedIn: 'root'
})
export class StyleStoreService {
  private loadedStyles:string[] = [];
  public StyleStore: Styles[] = [
    {name: 'bootstrap', href: '../../../assets/css/bootstrap.min.css'},
    {name: 'swiper', href: '../../../assets/css/swiper-bundle.css'},
    {name: 'reloadFonts', href: '../../../assets/css/fonts-reload.css'},
    {name: 'toastr', href: '../../../assets/css/toastr.css'},
  ];
  getLoadedStyles(): string[] {
    return this.loadedStyles
  }
  addLoadedStyle(stylesheet: string): void {
    this.loadedStyles = [...this.loadedStyles, stylesheet];
  }
  isLoadedStyle(stylesheet: string) {
    return this.loadedStyles.find(s => s === stylesheet);
  }
}