import { DYProductParameter, DynamicYieldSessionStorageService } from './dynamic-yield-session-storage.service';
import { Observable, map } from 'rxjs';

import { BrowserWindowRef } from '../components/providers/window.provider';
import { DYContext } from '@app/enums/dy-context.enum';
import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { ProductNames } from '../enums/product-names.enum';
import { environment as env } from "../../environments/environment";
import { environment } from '../../environments/environment';

@Injectable() export class DynamicYieldTaggingService {

  windowService: BrowserWindowRef;
  private static DY_TRAVEL_ADD_TO_CART = 'add-to-cart-v1';
  private static DY_TRAVEL_QUANTITY = 1;
  private static DY_TRAVEL_PURCHASE = 'purchase-v1';
  private static DY_LOGIN = 'login-v1';
  static readonly loginNameStep = 'Cédula';

  constructor(private httpClient: HttpClient, private dynamicYieldSessionStorageService: DynamicYieldSessionStorageService) {
    this.windowService = new BrowserWindowRef();
  }

  dyInitScripts(isLanding: boolean, productName?: string, isFinished: boolean = false) {
    try {
      if (productName) {
        this.getDYProductVariables(productName).subscribe(res => {
          this.dynamicYieldSessionStorageService.setDYProductParameters(res);
          this.LF_loadScript(isLanding, isFinished)
        });
      }
      else this.LF_loadScript(isLanding, isFinished)
    } catch (err) {
    }
  }

  private LF_loadScript(isLanding: boolean, isFinished: boolean): void {
    this.LF_loadDYScript1(`//cdn.dynamicyield.com/api/${environment.containerDyTravel}/api_dynamic.js`, isLanding, isFinished);
  }

  private LF_loadDYScript1(url: string, isLanding: boolean, isFinished: boolean): void {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;

    node.onload = () => {
      if (isLanding) {
        this.LF_loadDYScriptLanding(`//cdn.dynamicyield.com/api/${environment.containerDyTravel}/api_static.js`);
      } else if (!isFinished) {
        this.LF_loadDYScript(`//cdn.dynamicyield.com/api/${environment.containerDyTravel}/api_static.js`);
      } else {
        this.LF_loadDYScriptInsuranceFinish(`//cdn.dynamicyield.com/api/${environment.containerDyTravel}/api_static.js`);
      }

    }
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  private LF_loadDYScriptLanding(url: string): void {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;

    node.onload = () => {
      this.dySetContext('PRODUCT');
      this.dyLandingPage();
    }
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  private LF_loadDYScript(url: string): void {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;

    document.getElementsByTagName('head')[0].appendChild(node);
  }

  private LF_loadDYScriptInsuranceFinish(url: string): void {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;

    node.onload = () => {
      this.dySetContextOther(DYContext.Other);
      this.dyPageView('BG Travel Seguro activo Page');
    }
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  dySetContext(type: string) {
    const dyProductParameters = this.dynamicYieldSessionStorageService.getDYProductParameters();
    this.windowService.nativeWindow['DY'] = this.windowService.nativeWindow['DY'] || {};
    this.windowService.nativeWindow['DY'].recommendationContext = { type: type, data: [`${dyProductParameters.Data}`] };
  }

  dySetContextOther(type: string) {
    this.windowService.nativeWindow['DY'] = this.windowService.nativeWindow['DY'] || {};
    this.windowService.nativeWindow['DY'].recommendationContext = { type: type };
  }

  dyPageView(pageViewEvent: string) {
    try {
      const dyProductParameters = this.dynamicYieldSessionStorageService.getDYProductParameters();
      this.windowService.nativeWindow['DY'].API('event', {
        name: pageViewEvent,
        properties: {
          partner: dyProductParameters.Partner,
          market: dyProductParameters.Market,
          product: dyProductParameters.Product,
          vertical: dyProductParameters.Vertical,
          currency: dyProductParameters.Currency,
        }
      });
    } catch (err) { }
  }

  dyLandingPage() {
    try {
      const dyProductParameters = this.dynamicYieldSessionStorageService.getDYProductParameters();
      this.windowService.nativeWindow['DY'].API('event', {
        name: "Landing Page",
        properties: {
          partner: dyProductParameters.Partner,
          market: dyProductParameters.Market,
          product: dyProductParameters.Product,
          vertical: dyProductParameters.Vertical,
          currency: dyProductParameters.Currency,
        }
      });
    } catch (err) {
    }
  }

  dyLogin(cuid: string, cuidType: string) {
    try {
      this.windowService.nativeWindow['DY'].API('event', {
        name: 'Login',
        properties: {
          dyType: DynamicYieldTaggingService.DY_LOGIN,
          cuid: cuid,
          cuidType: cuidType
        }
      });

    } catch (error) {
    }
  }

  dyStartedQuote() {
    try {
      const dyProductParameters = this.dynamicYieldSessionStorageService.getDYProductParameters();
      this.windowService.nativeWindow['DY'].API('event', {
        name: 'Started Quote',
        properties: {
          partner: dyProductParameters.Partner,
          market: dyProductParameters.Market,
          product: dyProductParameters.Product,
          vertical: dyProductParameters.Vertical,
          currency: dyProductParameters.Currency,
        }
      });

    } catch (error) {
    }
  }

  dyQuoteCompletion() {
    try {
      const dyProductParameters = this.dynamicYieldSessionStorageService.getDYProductParameters();
      switch (dyProductParameters.Product) {
        case ProductNames.Travel:
          const travelData = this.dynamicYieldSessionStorageService.getDYTravelVariables();
          this.windowService.nativeWindow['DY'].API('event', {
            name: 'Quote Completion',
            properties: {
              partner: dyProductParameters.Partner,
              market: dyProductParameters.Market,
              product: dyProductParameters.Product,
              vertical: dyProductParameters.Vertical,
              currency: dyProductParameters.Currency,
              destinationType: this.checkNull(travelData.destinationType),
              travelType: this.checkNull(travelData.travelType),
              origin: this.checkNull(travelData.origin),
              destination: this.checkNull(travelData.destination),
              startDate: this.checkNull(travelData.startDate),
              endDate: this.checkNull(travelData.endDate),
              additionaltravellers: this.checkNull(travelData.additionalTravellers),
              principalAge: this.checkNull(travelData.principalAge),
              secondaryAge: this.checkNull(travelData.secondaryAge)
            }
          });
          break;

        case ProductNames.Auto:
          const autoData = this.dynamicYieldSessionStorageService.getDYAutoVariables();
          this.windowService.nativeWindow['DY'].API('event', {
            name: 'Quote Completion',
            properties: {
              partner: dyProductParameters.Partner,
              market: dyProductParameters.Market,
              product: dyProductParameters.Product,
              vertical: dyProductParameters.Vertical,
              currency: dyProductParameters.Currency,
              autoBrand: this.checkNull(autoData.autoBrand),
              autoModel: this.checkNull(autoData.autoModel),
              autoVersion: this.checkNull(autoData.autoVersion),
              autoYear: this.checkNull(autoData.autoYear),
              autoUse: this.checkNull(autoData.autoUse),
              autoType: this.checkNull(autoData.autoType),
            }
          });

          break;

        default:
          break;
      }

    } catch (error) {
    }
  }

  dyAddToCart(totalValue: number) {
    try {
      const dyProductParameters = this.dynamicYieldSessionStorageService.getDYProductParameters();
      this.windowService.nativeWindow['DY'].API('event', {
        name: 'Add to Cart',
        properties: {
          dyType: DynamicYieldTaggingService.DY_TRAVEL_ADD_TO_CART,
          productId: dyProductParameters.Data,
          value: totalValue,
          quantity: DynamicYieldTaggingService.DY_TRAVEL_QUANTITY,
          partner: dyProductParameters.Partner,
          market: dyProductParameters.Market,
          product: dyProductParameters.Product,
          vertical: dyProductParameters.Vertical,
          currency: dyProductParameters.Currency,
          cart: [
            {
              productId: dyProductParameters.Data,
              quantity: DynamicYieldTaggingService.DY_TRAVEL_QUANTITY,
              itemPrice: totalValue
            }
          ],
        }
      });

    } catch (error) {
    }
  }

  dyStartedCheckout(totalValue: number) {
    try {
      const dyProductParameters = this.dynamicYieldSessionStorageService.getDYProductParameters();
      this.windowService.nativeWindow['DY'].API('event', {
        name: 'Started checkout',
        properties: {
          productId: dyProductParameters.Data,
          value: totalValue,
          quantity: DynamicYieldTaggingService.DY_TRAVEL_QUANTITY,
          partner: dyProductParameters.Partner,
          market: dyProductParameters.Market,
          product: dyProductParameters.Product,
          vertical: dyProductParameters.Vertical,
          currency: dyProductParameters.Currency,
          cart: [
            {
              productId: dyProductParameters.Data,
              quantity: DynamicYieldTaggingService.DY_TRAVEL_QUANTITY,
              itemPrice: totalValue
            }
          ],
        }
      });

    } catch (error) {
    }
  }

  dyPurchaseIntention(totalValue: number) {
    try {
      const chubbData = this.dynamicYieldSessionStorageService.getDYTravelVariables();
      const dyProductParameters = this.dynamicYieldSessionStorageService.getDYProductParameters();
      this.windowService.nativeWindow['DY'].API('event', {
        name: "Purchase intention",
        properties: {
          productId: dyProductParameters.Data,
          value: totalValue,
          quantity: DynamicYieldTaggingService.DY_TRAVEL_QUANTITY,
          partner: dyProductParameters.Partner,
          market: dyProductParameters.Market,
          product: dyProductParameters.Product,
          vertical: dyProductParameters.Vertical,
          currency: dyProductParameters.Currency,

          destinationType: this.checkNull(chubbData.destinationType),
          travelType: this.checkNull(chubbData.travelType),
          origin: this.checkNull(chubbData.origin),
          destination: this.checkNull(chubbData.destination),
          startDate: this.checkNull(chubbData.startDate),
          endDate: this.checkNull(chubbData.endDate),
          additionaltravellers: this.checkNull(chubbData.additionalTravellers),
          principalAge: this.checkNull(chubbData.principalAge),
          secondaryAge: this.checkNull(chubbData.secondaryAge),
          paymentFrequency: this.checkNull(chubbData.paymentFrequency)
        }
      });

    } catch (error) {
    }
  }

  dyPurchase(uniqueTransactionId: string, totalValue: number) {
    const chubbData = this.dynamicYieldSessionStorageService.getDYTravelVariables();
    try {
      const dyProductParameters = this.dynamicYieldSessionStorageService.getDYProductParameters();
      this.windowService.nativeWindow['DY'].API('event', {
        name: 'Purchase',
        properties: {
          dyType: DynamicYieldTaggingService.DY_TRAVEL_PURCHASE,
          uniqueTransactionId: uniqueTransactionId,
          productId: dyProductParameters.Data,
          value: totalValue,
          quantity: DynamicYieldTaggingService.DY_TRAVEL_QUANTITY,
          partner: dyProductParameters.Partner,
          market: dyProductParameters.Market,
          product: dyProductParameters.Product,
          vertical: dyProductParameters.Vertical,
          currency: dyProductParameters.Currency,

          destinationType: this.checkNull(chubbData.destinationType),
          travelType: this.checkNull(chubbData.travelType),
          origin: this.checkNull(chubbData.origin),
          destination: this.checkNull(chubbData.destination),
          startDate: this.checkNull(chubbData.startDate),
          endDate: this.checkNull(chubbData.endDate),
          additionaltravellers: this.checkNull(chubbData.additionalTravellers),
          principalAge: this.checkNull(chubbData.principalAge),

          secondaryAge: this.checkNull(chubbData.secondaryAge),
          paymentFrequency: this.checkNull(chubbData.paymentFrequency),
          cart: [
            {
              productId: dyProductParameters.Data,
              quantity: DynamicYieldTaggingService.DY_TRAVEL_QUANTITY,
              itemPrice: totalValue
            }
          ],
        }
      });

    } catch (error) {
    }


  }

  dyError(message: string) {
    try {
      const chubbData = this.dynamicYieldSessionStorageService.getDYTravelVariables();
      const dyProductParameters = this.dynamicYieldSessionStorageService.getDYProductParameters();
      this.windowService.nativeWindow['DY'].API('event', {
        name: "System message",
        properties: {
          productId: dyProductParameters.Data,
          action: "error",
          message: message,
          partner: dyProductParameters.Partner,
          market: dyProductParameters.Market,
          product: dyProductParameters.Product,
          vertical: dyProductParameters.Vertical,
          leadId: this.checkNull(chubbData.leadId)
        }
      });
    } catch (error) {
    }

  }


  private checkNull(data) {
    return !data ? '' : data
  }

  getDYProductVariables(productName: string): Observable<DYProductParameter> {
    return this.httpClient.get<any>(`${env.baseUrl}DYProdParam/${productName}`).pipe(map((data) => data.data));
  }
}
