import { ActivatedRouteSnapshot, UrlSegment } from "@angular/router";

import { FieldCRM } from "@app/enums/fields-crm.enum";
import { SaleStatus } from "@app/models/campaign.model";

export const hasNumber = (amountsArray: number[], number: number): boolean => {
  return amountsArray.includes(number);
};

export const closestNumber = (
  amountsArray: number[],
  closeNumber: number
): number => {
  return amountsArray.reduce((prev, curr) => {
    return Math.abs(curr - closeNumber) < Math.abs(prev - closeNumber)
      ? curr
      : prev;
  });
};

export const getProductFromRouteData = (url: string): string => {
  return url.split("/")[1];
};

export const getPathFromUrlSegments = (array: UrlSegment[]): string => {
  return array.reduce((ac, cv) => `${ac}/${cv.path}`, "");
};

export const getDataForContainerPage = (
  shot: ActivatedRouteSnapshot,
  saleStatus: SaleStatus
) => {
  return {
    product: getProductFromRouteData(shot.data.path),
    //step: shot.url[0].path,
    saleType: saleStatus.type, // !== "VL" ? saleStatus.prefixUrl : shot.url[1].path,
    saleChannel: saleStatus.channel,
    //fileName: `${shot.url[2].path}`,
  };
};

export const cleanArrayMaxValid = (array: any[], criteriaMax: number):any[] => {
  return array.filter(e => e <= criteriaMax);
}

export const weightestCountry = (countriesSelected: string[], rawCountries: any[]) => {
  const countriesFiltered = rawCountries.filter(c => countriesSelected.includes(c.Name_ES));
  const weightestCountry = Math.max(...countriesFiltered.map(f => f.weight));
  return countriesFiltered.find(e => e.weight == weightestCountry);
}

export const weightestCountryES = (countriesSelected: string[], rawCountries: any[]) => {
  return weightestCountry(countriesSelected, rawCountries).Name_ES;
}

export const fillFields = (data: any, fields: any) => {
  if (data) {
    return fields.map(f => {
      let fieldValue = data[f?.name] || null;
      if (fieldValue) {
        fieldValue = fieldValue && fieldValue?.start && fieldValue?.end ? {
          start: new Date(fieldValue.start),
          end: new Date(fieldValue.end),
        } : f.shortDate ? data.birthdate.toString().substring(0, 10) : fieldValue;
        let fieldItemUpdate = {
          ...f,
          initialValue: fieldValue || null
        }
        return fieldItemUpdate;
      } else {
        return f;
      }
    });
  } else {
    return fields;
  }
}

export const getDateRangeFromPastYears = (years: number) => {
  const startDate = new Date();
  const endDate = new Date();
  startDate.setFullYear(startDate.getFullYear()-years)
  endDate.setFullYear(endDate.getFullYear()-years)
  startDate.setMonth(startDate.getMonth()-12)
  startDate.setHours(startDate.getHours()+24)
  return {
    startDate,
    endDate
  }
}

export const diffDays = (start, end) =>{
  const startDate = new Date(start);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(end);
  endDate.setHours(0, 0, 0, 1);
  let difference = endDate.getTime() - startDate.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
}

export const removeTags = (str) => {
	if ((str===null) || (str===''))
		return false;
	else
		str = str.toString();
	return str.replace( /(<([^>]+)>)/ig, '').replace(/\s+/g, ' ').trim();
}

export const getCurrentStringStep = (currentStep: number, data: any, saleStatus: any, stepId: any, subtractCurrentStep: boolean = false) => {
  let steps = data.config.initProgressSteps;
  let stepString = data.stepperInfo.step;
  let stepLabel = data.config.steps.find(e => e.id == stepId).label;
  if (saleStatus.type != "VL") {
    steps -= 1;
    if (subtractCurrentStep) {
      currentStep -= 1;
    }
  }
  stepString = stepString.replace("[[currentStep]]", currentStep);
  stepString = stepString.replace("[[steps]]", steps);
  return {
    steps,
    stepString,
    stepLabel,
    stepNumber: currentStep
  };
}

export const capitalizeWords = (str: string): string => {
  return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const getGenderEN = (gender: string): string => {
  const matchGender = [
    {
      es: FieldCRM.male,
      en: FieldCRM.genderMale
    },
    {
      es: FieldCRM.female,
      en: FieldCRM.genderFemale
    },
    {
      es: FieldCRM.other,
      en: FieldCRM.genderOther
    }
  ];
  return matchGender.find(e => e.es == gender).en;
}

export const testDialetrics = (input: string) => {
  let regex = /[áéíóúÁÉÍÓÚäÄëËïÏöÖüÜñÑ]/;
  return regex.test(input);
}

export const replaceDiacritics = (input: string) => {
  return input.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
}

export const dateFormat = (date: Date) => {
  if (!date) return '';
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
export const dateStringInRange = (dateStr: string, minDate: Date, maxDate: Date) => {
  const date = new Date(dateStr);
  date.setHours(0, 0, 0, 0);
  minDate.setHours(0, 0, 0, 0);
  maxDate.setHours(0, 0, 0, 0);
  return date.getTime() >=  minDate.getTime() && date.getTime() <= maxDate.getTime();
}