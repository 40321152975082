import { Field } from './field.model';

export class TimePickerField extends Field<string> {
  controlType = 'time';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}
