import { Injectable, Optional } from "@angular/core";

import { CampaignService } from "./campaign.service";
import { CookieService } from "ngx-cookie-service";
import { DatePipe } from "@angular/common";
import { GoogleAnalyticServiceService } from "@app/helpers/google-analytic-service.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment as env } from "../../environments/environment";
import { map } from "rxjs/operators";

export interface IEmailPayload {
  fullname: string;
  phone_number: string;
  identification_number: string;
  date_preference: string;
  hour_preference: string;
  product: string;
}

@Injectable({
  providedIn: "root",
})
export class SendgridService {
  constructor(
    private httpClient: HttpClient,
    private campaignService: CampaignService,
    private cookieService: CookieService,
    private googleAnalyticService: GoogleAnalyticServiceService,
    private datePipe: DatePipe
  ) {}

  sendEmailData(data) {
    const hourPref = data.hour_preference;
    if (data?.date_preference)
      data.date_preference = data.date_preference?.toLocaleDateString()
    if (hourPref instanceof Date) {
      data.hour_preference = this.datePipe.transform(
        data.hour_preference,
        "h:mm a"
      );
    }
    return this.httpClient
      .post<IEmailPayload>(`${env.baseUrl}schedule-call`, data)
      .pipe(map((data) => data));
  }

  sendReferEmailData(data) {
    return this.httpClient
      .post<IEmailPayload>(`${env.baseUrl}email-referido`, data)
      .pipe(map((data) => data));
  }

  sendReferBGEmailData(data) {
    data.textMessage = data.textMessage.replaceAll("@@", "**");
    const GACookie = this.cookieService.get("_ga");
    const GACookieStr = GACookie.substring(GACookie.indexOf(".", 4) + 1);
    const dataToSend = {
      GAValue: GACookieStr,
      ...data,
    };
    return this.httpClient
      .post<IEmailPayload>(`${env.baseUrl}email-referido-banco`, dataToSend)
      .pipe(
        map((data: any) => {
          this.googleAnalyticService.googleTaskManagerPush({
            event: "referrals_proccess",
            referrals_data: {
              flow_type: "email",
              seller_id: this.campaignService.getData()?.seller_id,
              operation_id_old: this.campaignService.getData()?.operation_id,
              operation_id: data.data.data.operation_id,
            },
          });
          return data;
        })
      );
  }
}
