import { Observable, of } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from "../../environments/environment";
import { map } from "rxjs/operators";

interface IIMEIPayloadData {
  IMEI: string | number;
}

interface ICampaignDetailData {
  campaignName: string;
  planName: string;
  level: number | string;
  totalPremiumValue: number;
  maxCoverageValue: number;
}

interface IStorageData {
  capacity: string;
  storageValue: string;
  campaigns: ICampaignDetailData[];
}

interface IIMEIInfoData {
  make: string;
  model: string;
  storage: IStorageData[];
}

interface IResponseIMEIInfoData {
  success: boolean;
  errorCode: number | string;
  errorData: string;
  data: IIMEIInfoData;
}

@Injectable({
  providedIn: "root",
})
export class QuoteMobileService {
  private data: any;

  constructor(private httpClient: HttpClient) {}

  public cleanData(res: IResponseIMEIInfoData): IIMEIInfoData {
    this.setData({ ...res.data });
    return this.getData();
  }
  public getData(): IIMEIInfoData {
    return this.data;
  }
  public setData(data: IIMEIInfoData): void {
    this.data = data;
  }

  getRemoteData(data: IIMEIPayloadData) {
    return this.httpClient
      .post<IResponseIMEIInfoData>(`${env.baseUrl}quote`, data)
      .pipe(map((data) => this.cleanData(data)));
  }
  remoteQuote(data: any) {
    return this.httpClient
      .post<any>(`${env.baseUrl}quote`, data)
      .pipe(map((data) => this.cleanData(data)));
  }
}
