import { Component, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-payfail",
  templateUrl: "./payfail.component.html",
  styleUrls: ["./payfail.component.scss"],
})
export class PayfailComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ModalComponent>) {}

  ngOnInit(): void {}

  closeModal($event) {
    $event.preventDefault();
    this.dialogRef.close();
  }
}
