import { CommonModalClickToCallComponent } from "../common-modal-click-to-call/common-modal-click-to-call.component";
import { CommonModalCoverageAndExclusionsComponent } from "../common-modal-coverage-and-exclusions/common-modal-coverage-and-exclusions.component";
import { CommonModule } from "@angular/common";
import { FormModule } from "../form/form.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoaderInterceptor } from "@app/shared/loader.interceptor";
import { LoaderModule } from "shared/loader.module";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { MaxtriesContentModalComponent } from "../maxtries-content-modal/maxtries-content-modal.component";
import { ModalComponent } from "./modal.component";
import { ModalContentIsCustomerComponent } from "../modal-content-is-customer/modal-content-is-customer.component";
import { ModalContentNoCustomerComponent } from "../modal-content-no-customer/modal-content-no-customer.component";
import { ModalLegacyComponent } from '../modal-legacy/modal-legacy.component';
import { NgModule } from "@angular/core";
import { PayfailComponent } from "../payfail/payfail.component";
import { ResponseTitleComponent } from "../response-title/response-title.component";
import { SafeHtmlPipe } from "@app/pipes/safe-html.pipe";

@NgModule({
    declarations: [
      ModalComponent,
      CommonModalClickToCallComponent,
      MaxtriesContentModalComponent,
      ModalContentNoCustomerComponent,
      ModalContentIsCustomerComponent,
      PayfailComponent,
      ResponseTitleComponent,
      SafeHtmlPipe,
      ModalLegacyComponent,
      CommonModalCoverageAndExclusionsComponent,
    ],
    imports: [CommonModule, FormModule, MatIconModule, MatButtonModule, MatDialogModule, MatTooltipModule, MatDatepickerModule, MatTabsModule],
    exports: [ModalLegacyComponent],
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptor,
        multi: true,
      },
    ]
  })
  export class ModalModule {}
  