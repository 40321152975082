import { Component, OnInit } from "@angular/core";

import { CampaignService } from "./../../../services/campaign.service";
import { CommonModalClickToCallComponent } from "../../../components/common-modal-click-to-call/common-modal-click-to-call.component";
import { ErrorListService } from "./../../../services/error-list.service";
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from "../../../components/modal/modal.component";
import { ProductService } from "./../../../services/product.service";
import { ResponseTitleComponent } from "../../../components/response-title/response-title.component";
import { ScheduleCallFieldService } from "./../../../services/schedule-call-field.service";
import { SendgridService } from "./../../../services/sendgrid.service";
import { TermsAndConditionsComponent } from "../../../components/common-modal-terms-and-conditions/common-modal-terms-and-conditions.component";
import { TitleModalConfig } from "./../../../shared/title-modal-config";

@Component({
  selector: "app-secure-footer",
  templateUrl: "./secure-footer.component.html",
  styleUrls: ["./secure-footer.component.scss"],
})
export class SecureFooterComponent implements OnInit {
  fields: any[];
  submitSrvFn: any;
  constructor(
    service: ScheduleCallFieldService,
    private sendgridService: SendgridService,
    private campaignService: CampaignService,
    private productService: ProductService,
    private errorListService: ErrorListService,
    public dialog: MatDialog
  ) {
    this.fields = service.getFields();
  }

  titleTerms: string = "Términos y Condiciones";
  titleClick: string = "¿Necesitas ayuda? ¡Contáctanos!";
  ngOnInit(): void {}
}
