import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalLegacyService {
  private _modalClickToCallSource = new Subject<string>();
  modalClickToCall$ = this._modalClickToCallSource.asObservable();
  constructor() { }

  sendModalClickToCall(message: string) {
    this._modalClickToCallSource.next(message);
  }
}
