import { Component, OnInit } from "@angular/core";

import { CampaignService } from "./../../../services/campaign.service";
import { CommonModalClickToCallComponent } from "../../../components/common-modal-click-to-call/common-modal-click-to-call.component";
import { ErrorListService } from "./../../../services/error-list.service";
import { Events } from "@app/enums/events.enum";
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from "../../../components/modal/modal.component";
import { ProductService } from "./../../../services/product.service";
import { ResponseTitleComponent } from "../../../components/response-title/response-title.component";
import { ScheduleCallFieldService } from "./../../../services/schedule-call-field.service";
import { SendgridService } from "./../../../services/sendgrid.service";
import { TitleModalConfig } from "./../../../shared/title-modal-config";

@Component({
  selector: "app-secure-header",
  templateUrl: "./secure-header.component.html",
  styleUrls: ["./secure-header.component.scss"],
})
export class SecureHeaderComponent implements OnInit {
  campaignData;
  fields: any[];
  submitSrvFn;
  currentRoute: string;

  constructor(
    service: ScheduleCallFieldService,
    private sendgridService: SendgridService,
    private campaignService: CampaignService,
    private productService: ProductService,
    private errorListService: ErrorListService,
    public dialog: MatDialog
  ) {
    this.fields = service.getFields();
  }

  ngOnInit(): void {
    this.campaignData = this.campaignService.getData();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      id: "dialog-click-to-call",
      autoFocus: false,
      panelClass: "panel-mobile",
      minHeight: "80vh",
      maxHeight: "100vh",
      data: {
        title: "¿Necesitas ayuda? ¡Contáctanos!",
        component: CommonModalClickToCallComponent,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == Events.ScheduleSpaceCallEvent) {
        this.openScheduleDialog();
      }
    });
  }

  openScheduleDialog(): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      id: "dialog-schedule-call",
      autoFocus: false,
      backdropClass: "backdrop-blur",
      panelClass: "panel-mobile",
      minHeight: "80vh",
      maxHeight: "100vh",
      data: {
        title: "Agenda una llamada",
        description:
          "Proporciona tus datos y pronto nos pondremos en contacto:",
        formName: "schedule-call-form",
        fields: this.fields,
        bottomText:
          '<p>Al presionar "Enviar", declaro haber leído y acepto el contenido de la cláusula de <a id="consent-of-uses-modal" href="javascript:void(0)" (click)="openModalConsentOfUses()">Tratamiento de Uso de Datos</a> de Chubb Seguros.</p>',
        submitFn: this.sendEmail,
        submitSrvFn: this.sendgridService.sendEmailData,
        submitText: "Enviar",
      },
    });
    let schedulePayload = {};

    dialogRef.componentInstance.parentEvent.subscribe((result) => {
      //TODO change date_preference
      const checkToday = () => {
        const todayDate = new Date();
        const datePreference = result.data?.date_preference;
        const todayString = `${todayDate.getFullYear()}-${
          todayDate.getMonth() + 1
        }-${todayDate.getDate()}`;
        const date = datePreference
          ? `${datePreference.getFullYear()}-${
              datePreference.getMonth() + 1
            }-${datePreference.getDate()}`
          : datePreference;
      };
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.message == "success") {
        this.openScheduleResponse(result);
      } else if (result.message == "unknownError") {
        this.errorListService.openModalError();
      }
    });
  }

  openScheduleResponse(typeRes): void {
    var configTitle: TitleModalConfig;
    if (typeRes.message === "success") {
      configTitle = {
        bgclass: "success",
        icon: "check_circle_outline",
        emphasisTitle: "Hemos recibido tu solicitud para agendar una llamada",
      };
    }
    const dialogRef = this.dialog.open(ModalComponent, {
      id: "dialog-schedule-success",
      autoFocus: false,
      backdropClass: "backdrop-blur",
      panelClass: "panel-mobile",
      minHeight: "80vh",
      maxHeight: "100vh",

      data: {
        titleComponent: ResponseTitleComponent,
        configTitle,
        confirmText:
          "Uno de nuestros agentes se pondrá en contacto contigo lo antes posible.",
        confirmActions: {
          confirmLabel: "Aceptar",
        },
      },
    });
  }

  public sendEmail(input) {
    const campaignData = this.campaignService.getData();
    var dataToSend = {
      ...input,
      product: campaignData
        ? this.productService.getProductByName(campaignData.product_id).label
        : "",
    };
    return this.submitSrvFn(dataToSend);
  }
}
