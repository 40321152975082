import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-field-helpertooltip',
  templateUrl: './field-helpertooltip.component.html',
  styleUrls: ['./field-helpertooltip.component.css']
})
export class FieldHelpertooltipComponent implements OnInit {
  @Input() helperTooltip: any;
  constructor() { }

  ngOnInit(): void {
  }

}
